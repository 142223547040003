import React from 'react';
import { Typography } from 'antd';
import CommandsTable from '../CommandsTable';

export default function Commands({ guild: { options: { modules }} }) {
    return (
        <div style={{ padding: '2rem', maxWidth: '100rem', margin: '0 auto' }}>
            <Typography.Title>Commands</Typography.Title>
            <CommandsTable guildModules={modules} />
        </div>
    );
}