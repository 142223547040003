import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './Components.module.css';

export function Loading() {
    return (
        <div className={styles.dimmer}>
            <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: '5em' }} />} />
        </div>
    )
}
export function LoadingDimmer() {
    return (
        <div className={styles.loadingDimmer}>
            <Spin />
        </div>
    )
}