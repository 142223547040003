import React, { useState, useEffect, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import axios from '../../axios';
import websocket from '../websocket.js';
import { Typography, Image, message, Table, Button, Alert, Skeleton, Row, Col, Space, Select, Slider, Progress, Input, Grid } from 'antd';
import { PlayCircleOutlined, UserOutlined, ClockCircleOutlined, CaretRightOutlined, StepForwardOutlined, PauseOutlined, BorderOutlined, DeleteOutlined, SwapOutlined } from '@ant-design/icons';
import ReactGA from 'react-ga';

const MusicQueue = React.memo(function MusicQueue({ guildOptions, queue, duration }) {
	const queueList = useMemo(() => queue && queue.slice(1), [queue]);
	const breakpoints = Grid.useBreakpoint();
	const [ loading, setLoading ] = useState(false);
	const [ search, setSearch ] = useState('');

	function addSong() {
		if (!search) return;

		if (process.env.REACT_APP_ANALYTICS_KEY) ReactGA.event({
			category: 'Music',
			action: 'Add Song',
			transport: 'beacon'
		});

		setLoading(true);
		axios.post('/modules/music/play', { query: search }).then(() => {
			setSearch('');
			setLoading(false);
			message.info('Song Added');
		}).catch(err => {
			if (err.response.data && err.response.data.error) message.error(err.response.data.error);
			else message.error('An error occured');
			setLoading(false);
		})
	}
	
	function handleShuffle(e) {
		e.preventDefault()
		if (process.env.REACT_APP_ANALYTICS_KEY) ReactGA.event({
			category: 'Music',
			action: 'Shuffle Queue',
			transport: 'beacon'
		});
		
		setLoading(true);
		axios.post('/modules/music/queue/shuffle').then(() => {
			setLoading(false);
			message.info('Queue shuffled');
		}).catch(err => {
			if (err.response.data && err.response.data.error) message.error(err.response.data.error);
			else message.error('An error occured');
			setLoading(false);
		})
	}
	function handleClearQueue(e) {
		e.preventDefault()
		if (process.env.REACT_APP_ANALYTICS_KEY) ReactGA.event({
			category: 'Music',
			action: 'Clear Queue',
			transport: 'beacon'
		});
		
		setLoading(true);
		axios.delete('/modules/music/queue').then(() => {
			setLoading(false);
			message.info('Queue cleared');
		}).catch(err => {
			if (err.response.data && err.response.data.error) message.error(err.response.data.error);
			else message.error('An error occured');
			setLoading(false);
		})
	}

	function removeSong(position) {
		if (process.env.REACT_APP_ANALYTICS_KEY) ReactGA.event({
			category: 'Music',
			action: 'Remove Song',
			transport: 'beacon'
		});
		
		setLoading(true);
		axios.delete(`/modules/music/queue/${position+1}`).then(() => {
			setLoading(false);
			message.info('Song removed');
		}).catch(err => {
			if (err.response.data && err.response.data.error) message.error(err.response.data.error);
			else message.error('An error occured');
			setLoading(false);
		})
	}

	if (!queue) return <></>;

	return (
		<div style={{ marginTop: '2rem' }}>
			{ breakpoints.sm ? <Typography.Title level={2}>Queue ({queue.length-1} songs - {duration})</Typography.Title> : <div>
				<Typography.Title level={2} style={{ marginBottom: 0 }}>Queue</Typography.Title>
				<p style={{ fontSize: '1.3em' }}>{queue.length-1} songs - {duration}</p>
			</div> }
			<div style={{ display: 'flex', flexWrap: breakpoints.sm ? undefined : 'wrap', justifyContent: breakpoints.sm ? undefined : 'center' }}>
				{ guildOptions.functions['shuffle'] && queue.length > 1 && <Button disabled={loading} icon={<SwapOutlined />} onClick={handleShuffle} style={{ margin: '0.2rem 0.5rem'}}>Shuffle</Button> }
				{ guildOptions.functions['clear'] && queue.length > 1 && <Button disabled={loading} icon={<DeleteOutlined />} danger onClick={handleClearQueue} style={{ margin: '0.2rem 0.5rem'}}>Clear</Button> }
				<Input.Search disabled={loading} placeholder='Add Song' allowClear enterButton="Add" onSearch={addSong} style={{ flexGrow: 1, margin: '0.2rem 0.5rem' }} value={search} onChange={e => setSearch(e.target.value)} />
			</div>

			<Table dataSource={queueList} size="small" locale={{ emptyText: 'There is nothing queued' }} pagination={false}>
				<Table.Column title="#" dataIndex="position" key="position" render={(text, _, i) => i+1} />
				<Table.Column title="" dataIndex="thumbnail" key="thumbnail" render={(_, song) => song.thumbnail ? (
					<a href={song.url} target="_blank" rel="noopener noreferrer"><Image src={song.thumbnail} alt={'Image of ' + song.title} height='3rem' style={{ objectFit: 'contain', minWidth: '1rem' }} /></a>
				) : <></>} />
				<Table.Column title="Title" dataIndex="title" key="title" />
				<Table.Column title="Artist" dataIndex="artist" key="artist" responsive={['sm']} />
				<Table.Column title="Duration" dataIndex="duration" key="duration" responsive={['md']} />
				<Table.Column title="" dataIndex="actions" key="actions" render={(text, _, i) => guildOptions.functions['remove'] ? <Button danger onClick={() => removeSong(i)} icon={<DeleteOutlined />} /> : <></> } />
			</Table>
		</div>
	);
});

const SongInfo = React.memo(function SongInfo({ guildOptions, music, music: { songs, volume }}) {
	const breakpoints = Grid.useBreakpoint();
	const [ loading, setLoading ]  = useState(false);

	function handleStopSongs(e) {
		e.preventDefault()
		if (process.env.REACT_APP_ANALYTICS_KEY) ReactGA.event({
			category: 'Music',
			action: 'Stop Music',
			transport: 'beacon'
		});

		setLoading(true);
		axios.post('/modules/music/stop').then(() => {
			setLoading(false);
			message.info('Music Stopped');
		}).catch(err => {
			if (err.response.data && err.response.data.error) message.error(err.response.data.error);
			else message.error('An error occured');
			setLoading(false);
		});
	}
	function handleSetRepeat(event, data) {
		if (process.env.REACT_APP_ANALYTICS_KEY) ReactGA.event({
			category: 'Music',
			action: 'Set Repeat',
			transport: 'beacon'
		});
		
		setLoading(true);
		axios.post('/modules/music/repeat', { mode: data.value }).then(() => {
			setLoading(false);
			message.info('Repeat Set');
		}).catch(err => {
			if (err.response.data && err.response.data.error) message.error(err.response.data.error);
			else message.error('An error occured');
			setLoading(false);
		});
	}
	function handleSkipSong(e) {
		e.preventDefault();
		if (process.env.REACT_APP_ANALYTICS_KEY) ReactGA.event({
			category: 'Music',
			action: 'Skip Song',
			transport: 'beacon'
		});
		
		setLoading(true);
		axios.post('/modules/music/skip').then(() => {
			setLoading(false);
			message.info('Skipped');
		}).catch(err => {
			if (err.response.data && err.response.data.error) message.error(err.response.data.error);
			else message.error('An error occured');
			setLoading(false);
		});
	}
	function handleTogglePause(e) {
		e.preventDefault();
		if (process.env.REACT_APP_ANALYTICS_KEY) ReactGA.event({
			category: 'Music',
			action: 'Toggle Pause',
			transport: 'beacon'
		});
		
		setLoading(true);
		axios.post('/modules/music/pause').then(() => {
			setLoading(false);
			message.info(music.paused ? 'Resumed' : 'Paused');
		}).catch(err => {
			if (err.response.data && err.response.data.error) message.error(err.response.data.error);
			else message.error('An error occured');
			setLoading(false);
		});
	}


	return (
		<>
			<Row gutter={16}>
				<Col span={{ sm: 24, md: 12 }} style={{ width: breakpoints.md ? '50%' : '100%' }}>
					<span style={{ fontSize: '1.4em', marginBottom: 0 }}>Now Playing</span>
					<Typography.Title level={2}>{ songs[0].title }</Typography.Title>

					{ (breakpoints.md || (!songs[0].highThumbnail && !songs[0].thumbnail)) ? null : <a href={songs[0].url} target='_blank' rel='noopener noreferrer'><Image src={songs[0].highThumbnail || songs[0].thumbnail} alt={`Thumbnail of ${songs[0].title}`} preview={false} width="20rem" /></a> }

					<ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
						<li><UserOutlined style={{ marginRight: '1rem' }} /><label>Artist: </label>{ songs[0].artist }</li>
						<li><ClockCircleOutlined style={{ marginRight: '1rem' }} /><label>Duration: </label>{ songs[0].duration }</li>
					</ul>

					<VolumeSlider volume={volume} guildOptions={guildOptions} />

					<Space style={{ margin: '1rem 0', justifyContent: breakpoints.md ? undefined : 'center' }}>
						{ music.paused && <Button icon={<CaretRightOutlined />} disabled={loading || !(guildOptions.functions['resume'] || guildOptions.functions['play'])} onClick={handleTogglePause} type="primary">{ breakpoints.sm ? 'Play' : ''}</Button> }
						{ !music.paused && <Button icon={<PauseOutlined />} disabled={loading || !(guildOptions.functions['pause'])} onClick={handleTogglePause} type="primary">{ breakpoints.sm ? 'Pause' : ''}</Button> }
						{ guildOptions.functions['stop'] && <Button disabled={loading} icon={<BorderOutlined />} onClick={handleStopSongs} danger>{ breakpoints.sm ? 'Stop' : ''}</Button> }
						{ guildOptions.functions['skip'] && <Button disabled={loading} icon={<StepForwardOutlined />} onClick={handleSkipSong}>{ breakpoints.sm ? 'Skip' : ''}</Button> }

						<Select value={music.repeat} onChange={handleSetRepeat} disabled={loading}>
							<Select.Option value={0}>Repeat Off</Select.Option>
							<Select.Option value={1}>Repeat Song</Select.Option>
							<Select.Option value={2}>Repeat Playlist</Select.Option>
						</Select>
					</Space>
				</Col>
				{ breakpoints.md && <Col span={{ sm: 24, md: 12 }} style={{ textAlign: 'right', width: '50%' }}>
					{ (!songs[0].highThumbnail && !songs[0].thumbnail) ? null : <a href={songs[0].url} target='_blank' rel='noopener noreferrer'><Image src={songs[0].highThumbnail || songs[0].thumbnail} alt={`Thumbnail of ${songs[0].title}`} preview={false} width="20rem" /></a> }
				</Col> }
			</Row>
		</>
	);
});

const VolumeSlider = React.memo(function VolumeSlider({ guildOptions, volume }) {
	const [ localVolume, setLocalVolume ] = useState(null);
	const [ loading, setLoading ] = useState(false);

	useEffect(() => {
		setLocalVolume(null);
	}, [volume]);

	function handleVolumeChange(value) {
		if (guildOptions.functions['volume']) {
			setLocalVolume(value);
		}
	}
	function sendVolumeChange() {
		if (volume === localVolume) return;

		if (guildOptions.functions['volume']) {
			if (process.env.REACT_APP_ANALYTICS_KEY) ReactGA.event({
				category: 'Music',
				action: 'Change Volume',
				transport: 'beacon'
			});

			setLoading(true);
			axios.post('/modules/music/volume', { volume: localVolume }).then(() => {
				setLoading(false);
				message.info('Volume Set');
			}).catch(err => {
				if (err.response.data && err.response.data.error) message.error(err.response.data.error);
				else message.error('An error occured');
				setLoading(false);
			});
		}
	}

	return (
		<div style={{textAlign: 'center', marginTop: '1rem'}}>
			<Slider min={0} max={200} value={localVolume || volume} onChange={handleVolumeChange} onAfterChange={sendVolumeChange} disabled={loading} />
			<label style={{fontWeight: 700}}>Volume: {localVolume || volume}%</label>
		</div>
	);
});

function CurrentlyPlaying({ guildOptions, playtime, music }) {
	if (music.playing) {
		return (
			<div>
				<SongInfo guildOptions={guildOptions} music={music} />
				<Progress format={() => `${playtime.playtime || '0m'}/${music.songs[0].duration}`} percent={playtime.percentage || 0} className="music-progress" status="normal" />
			</div>
		)
	}

	return <Alert message="Not Playing" description="Join a voice channel and play a song to get started" type="info" showIcon={true} icon={<PlayCircleOutlined />} />;
}
function MusicPlayer({ guildOptions, guildId }) {
	const musicInfo = useQuery(['/modules/music', guildId], {
		refetchOnMount: true,
		refetchOnWindowFocus: true
	});
	const queryClient = useQueryClient();
	const [ playtime, setPlaytime ] = useState({});

	useEffect(() => {
		websocket.on('music', data => queryClient.setQueryData(['/modules/music', guildId], { data }));
		websocket.on('playtime', data => setPlaytime(data));
		websocket.emit('command', 'music.subscribe', guildId);

		return(() => {
			websocket.emit('command', 'music.unsubscribe', guildId);
			websocket.removeListener('music');
			websocket.removeListener('playtime');
		})
	}, [guildId, queryClient]);

	return (
		<div style={{ padding: '2rem', maxWidth: '100rem', margin: '0 auto' }}>
			<Typography.Title>Music</Typography.Title>

			<Skeleton loading={musicInfo.isLoading} active paragraph={{ rows: 4 }}>
				<CurrentlyPlaying music={musicInfo.data} guildOptions={guildOptions} playtime={playtime} />
				<MusicQueue duration={musicInfo.data ? musicInfo.data.duration : ''} queue={musicInfo.data ? musicInfo.data.songs: []} guildOptions={guildOptions} />
			</Skeleton>
		</div>
	);
}

export default MusicPlayer;