import React from 'react';
import { Typography, Alert } from 'antd';
import CommandsTable from './CommandsTable';

export default function Commands() {
    return (
        <div style={{ padding: '2rem', maxWidth: '100rem', margin: '0 auto' }}>
            <Typography.Title>All Commands</Typography.Title>
            
            <Alert type="info" message={<span>
                This table lists all Mizar's commands. Not all of these commands may be enabled within your guild, you might not have permission to use them or they might have been renamed.
                <br />See your guild command page for more information on what is available.
            </span>} style={{ marginBottom: '2rem' }} showIcon={true} />

            <CommandsTable />
        </div>
    );
}