import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Avatar, message, Table, Typography, Grid, Space } from 'antd';
import { StarOutlined, HourglassOutlined, MessageOutlined } from '@ant-design/icons';
import './Leaderboard.css';

const Leaderboard = React.memo(function Leaderboard({ guildId }) {
	const [ pagination, setPagination ] = useState({ current: 1, pageSize: 100, showSizeChanger: false });
	const { current: currentPage } = pagination;

	const usersInfo = useQuery([`/modules/levelling/leaderboard?page=${currentPage}`, guildId], { onError: () => message.error("Couldn't fetch leaderboard.") });
	const { data: usersData } = usersInfo;
	
	useEffect(() => {
		if (!usersData) return;

		setPagination(pagination => ({ ...pagination, total: usersData.count }));
	}, [usersData]);

	function onTableChange(pagination) {
		setPagination(pagination);
	}

	const breakpoint  = Grid.useBreakpoint();

    var expandable = null;
    if (!breakpoint.md) {
        if (breakpoint.sm) expandable = {
            expandedRowRender: row => <Space direction="vertical">
				<Space><MessageOutlined /><strong>Messages Sent: </strong>{row.messagesSent} msgs</Space>
				<Space><HourglassOutlined /><strong>VC Time: </strong>{row.vcTime}</Space>
			</Space>
        };
        else expandable = {
            expandedRowRender: row => <Space direction="vertical">
				<Space><StarOutlined /><strong>Experience: </strong>{row.experience}</Space>
				<Space><MessageOutlined /><strong>Messages Sent: </strong>{row.messagesSent} msgs</Space>
				<Space><HourglassOutlined /><strong>VC Time: </strong>{row.vcTime}</Space>
			</Space>
        };
    }

	return (
		<div style={{ padding: '2rem', maxWidth: '100rem', margin: '0 auto' }}>
			<Typography.Title>Leaderboard</Typography.Title>

			<Table loading={usersInfo.isLoading} dataSource={usersData ? usersData.users : []} size="small" onRow={(user, i) => ({ alternate: i % 2 ? 'true' : '', style: { background: `linear-gradient(to right, #00bc8c ${user.levelPercentage}%, ${i % 2 ? '#25272a' : '#292b2f'} ${user.levelPercentage}%)`}})} className="leaderboard-table" pagination={pagination} onChange={onTableChange} locale={{ emptyText: "No users found" }} expandable={expandable} rowKey="name">
				<Table.Column title="#" dataIndex="position" key="position" />
				<Table.Column title="Name" dataIndex="name" key="name" render={(_, user) => (
					<>
						<Avatar src={user.avatar} alt={`Avatar of ${user.name}`} style={{ marginRight: '0.5rem' }} />
						{user.name}
					</>
				)} />
				<Table.Column title="Level" dataIndex="level" key="level" />
				<Table.Column title="Experience" dataIndex="experience" key="experience" responsive={["sm"]} />
				<Table.Column title="Messages" dataIndex="messagesSent" key="messagesSent" responsive={["md"]} />
				<Table.Column title="VC Time" dataIndex="vcTime" key="vcTime" responsive={["md"]} />
			</Table>
		</div>
	);
});

export default Leaderboard;