import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Button, Typography, Modal, Image, Space, Grid } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Docs from './Docs';
import { description, features, keywords } from '../features';
import styles from './About.module.css';
import { JackInTheBox, Roll, Fade, Zoom } from 'react-awesome-reveal';
import { useQuery } from 'react-query';

function About({ welcome, middleContent }) {
    const [ selectedFeature, setSelectedFeature ] = useState(null);
    const [ showFeature, setShowFeature ] = useState(false);

    const [ userCount, setUserCount ] = useState(null);
    const [ guildCount, setGuildCount ] = useState(null);
    const [ lineCount, setLineCount ] = useState(null);
    const [ commandCount, setCommandCount ] = useState(null);

    const ping = useQuery(['/ping']);
    const { data: pingData } = ping;

    const breakpoints = Grid.useBreakpoint();

    useEffect(() => {
        var userIncrease, guildIncrease, lineIncrease, commandIncrease;
        const userTarget = (pingData && pingData.users && pingData.users-1) || 365000;
        const guildTarget = (pingData && pingData.guilds) || 500;
        const lineTarget = 31;
        const commandTarget = (pingData && pingData.commands) || 106;

        function increaseUserCount() {
            setUserCount(count => {
                let increase = count + Math.ceil(userTarget/6);
                if (increase <= userTarget && increase !== 0) {
                    userIncrease = setTimeout(increaseUserCount, 50);
                    return increase;
                }
                return userTarget;
            });
        }
        function increaseGuildCount() {
            setGuildCount(count => {
                let increase = count + Math.ceil(guildTarget/7);

                if (increase <= guildTarget && increase !== 0) {
                    guildIncrease = setTimeout(increaseGuildCount, 55);
                    return increase;
                }
                return guildTarget;
            });
        }
        function increaseLineCount() {
            setLineCount(count => {
                let increase = count + Math.ceil(lineTarget/5);
                if (increase <= lineTarget && increase !== 0) {
                    lineIncrease = setTimeout(increaseLineCount, 40);
                    return increase;
                }
                return lineTarget;
            });
        }
        function increaseCommandCount() {
            setCommandCount(count => {
                let increase = count + Math.floor(commandTarget/4);
                if (increase <= commandTarget) {
                    commandIncrease = setTimeout(increaseCommandCount, 60);
                    return increase;
                }
                return commandTarget;
            });
        }
        increaseUserCount();
        increaseGuildCount();
        increaseLineCount();
        increaseCommandCount();

        return () => {
            if (userIncrease) clearTimeout(userIncrease);
            if (guildIncrease) clearTimeout(guildIncrease);
            if (lineIncrease) clearTimeout(lineIncrease);
            if (commandIncrease) clearTimeout(commandIncrease);
        };
    }, [pingData]);

    return (
        <div style={{ padding: '0 2rem 2rem' }}>
            <div style={{ margin: '0 -2rem 2rem', position: 'relative' }}>
                <div style={{ background: '#292b2f', position: 'absolute', bottom: 0, left: 0, right: 0, top: 0, fontSize: '2em', lineHeight: '1.3em' }}>
                    <Space wrap align="center" style={{ justifyContent: 'center', height: '100%', overflow: 'hidden' }}>
                        { keywords.map(feature => <span key={`${feature}-1`}>{feature}</span>) }
                        { keywords.map(feature => <span key={`${feature}-2`}>{feature}</span>) }
                    </Space>
                </div>

                <div style={{ background: '#292b2ff5', position: 'relative', padding: '1.5rem 1rem 2rem' }}>
                    <div style={{ textAlign: 'center', maxWidth: '60rem', margin: '0 auto' }}>
                        {welcome && <p style={{ fontSize: '1.4em', marginBottom: 0 }}>Welcome to</p>}
                        <Typography.Title style={{ fontSize: '3.8em' }}>Mizar</Typography.Title>
                        <img src="/logo-256.png" alt="Mizar's logo" style={{ width: '170px', height: '170px', borderRadius: '50%', margin: '0 0 1rem' }} />
                        <p style={{fontSize: '1.4em', maxWidth: '60rem', flexGrow: 1}}>{description} </p>

                        <Zoom duration={300} triggerOnce>
                            <Link to="/invite"><Button type="primary" size="large" icon={<PlusOutlined />} style={{ marginTop: '1rem', fontSize: '1.5em', minHeight: '3.5rem' }} className="wrap-button">Add Mizar to Your Discord Server Today!</Button></Link>
                        </Zoom>
                    </div>
                </div>
            </div>

            <div style={{ maxWidth: '100rem', margin: '0 auto' }}>
                { middleContent }

                <Typography.Title level={2}>Why Choose Mizar?</Typography.Title>
                <Row gutter={16} style={{ marginBottom: '2rem' }}>
                    <Col span={breakpoints.md ? 16 : 24} className={styles.whyMizar}>
                        I assist over <em>{ userCount !== null ? userCount.toLocaleString() : '250,000'}{ (pingData && pingData.users) ? '' : '+' } users</em> in <em>{ guildCount !== null ? guildCount.toLocaleString() : '200'}{ (pingData && pingData.guilds) ? '' : '+' } guilds</em> with my <em>{ lineCount !== null ? lineCount : '26'}k+ lines of code</em>. My webpanel and variety of <em>{ commandCount !== null ? commandCount : '95'}{ (pingData && pingData.commands) ? '' : '+' } commands</em> provide almost every feature needed with complete control. And the best part? It's <em>100% free</em>!

                        <div className={`${styles.quotes} ${!breakpoints.sm ? styles.quotesSmall : ''}`}>
                            <Roll cascade triggerOnce damping={0.2} direction="left" duration={600} fraction={0.2}>
                                <div>
                                    <blockquote>This bot is really cool and has many features! I like how I can control it through a fancy dashboard as well. Mizar is an excellent bot.</blockquote>
                                    <span>- 185O</span>
                                </div>
                                <div>
                                    <blockquote>Undoubtedly one of the best bots.</blockquote>
                                    <span>- Frank A</span>
                                </div>
                                <div>
                                    <blockquote>A complete bot with full customization. Highly recommended!</blockquote>
                                    <span>- Rivera</span>
                                </div>
                            </Roll>
                        </div>
                    </Col>
                    <Col span={breakpoints.md ? 8 : 24} style={{ textAlign: breakpoints.md ? 'right' : 'center' }}>
                        <Fade triggerOnce duration={600} fraction={0.2}>
                            <img src="/images/admin-preview.jpg" alt="Admin Panel" style={{ maxHeight: '20rem', border: '0.3rem solid var(--color-grey-1)' }} />
                        </Fade>
                    </Col>
                </Row>
                
                <Typography.Title level={2}>Features<Docs path="" /></Typography.Title>
                <Row gutter={16}>
                    { features.map((feature, i) => (
                        <Col xs={24} sm={12} md={8} lg={6} key={i}>
                            <JackInTheBox triggerOnce delay={i % 4 * 50}>
                                <Card cover={<img alt={feature.title} src={feature.image || '/logo.png'} />} style={{ marginBottom: '1rem', minHeight: '25rem' }} hoverable={!!feature.list} onClick={() => {
                                    if (feature.list) {
                                        setSelectedFeature(feature);
                                        setShowFeature(true)
                                    }
                                }}>
                                    <Card.Meta title={feature.title} description={feature.description} />
                                </Card>
                            </JackInTheBox>
                        </Col>
                    ))}
                </Row>

                <Link to="/commands"><Button size="large" block>View All My Commands</Button></Link>
            </div>

            <Modal title={selectedFeature && selectedFeature.title} visible={showFeature} cancelText="Close" okButtonProps={{ style: { display: 'none' }}} onOk={() => setShowFeature(false)} onCancel={() => setShowFeature(false)} destroyOnClose={true} width={'min(600px, 90%)'}>
                { selectedFeature && <>
                    <Row gutter={16}>
                        <Col xs={24} sm={10}>
                            <Image alt={selectedFeature.title} src={selectedFeature.image} preview={false} />
                        </Col>
                        <Col xs={24} sm={12}>
                            <Typography.Title level={3}>{selectedFeature.title}</Typography.Title>
                            <p>{selectedFeature.description}</p>
                        </Col>
                    </Row>

                    <ul style={{ margin: '1rem 0' }}>
                        { selectedFeature.list.map((point, i) => <li key={i}>{point}</li>) }
                    </ul>
                </> }
                
            </Modal>
        </div>
    ) 
}

export default About;
