import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams, Link, useHistory } from 'react-router-dom';
import { Result, Button, Typography, Image, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import HCaptcha from "@hcaptcha/react-hcaptcha";
import axios from '../axios';
import websocket from './websocket';

import { Loading } from './Components';

function Invite({ authed, renderUnauthed }) {
    const { guildId } = useParams();
    const history = useHistory();

    const inviteInfo = useQuery([`/modules/joining/invite/${guildId}`, authed]);
    const [ captchaResponse, setCaptchaResponse ] = useState(null);
    const [ joinPopup, setJoinPopup ] = useState(null);
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        websocket.on('invite.joined', () => {
            if (joinPopup) {
                joinPopup.close();
                setJoinPopup(null);
            }

            history.push(`/g/${guildId}`);
        });

        return (() => websocket.off('invite.joined'));
    }, [joinPopup, guildId, history])
    
    function handleJoinGuild() {
        if (joinPopup) {
            joinPopup.close();
            setJoinPopup(null);
        }

        setLoading(true);
        setCaptchaResponse(null);
        axios.post(`/modules/joining/invite/${guildId}`, { captcha: captchaResponse }).then(response => {
            if (response.data && response.data.url) setJoinPopup(window.open(response.data.url, '_blank', 'width=500, height=800, menubar=no, toolbar=no'));
            else message.error('An error occured. Please try again later');
            setLoading(false);
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.error) message.error(error.response.data.error);
            else message.error('An error occured. Please try again later');
            setLoading(false);
        });
    }

    if (inviteInfo.isLoading) return <Loading />;
    if (loading) return <Loading />;
    if (inviteInfo.data && inviteInfo.data.authorise && !authed) return renderUnauthed();

    if (!inviteInfo.data || !inviteInfo.data.guild) return (
        <Result status="error" title="Invalid Invite Link" subTitle="I couldn't find a guild with that link" extra={[
            <Link to="/" key="home"><Button type="primary">Home</Button></Link>
        ]} />
    );
    return (
        <div style={{ padding: '2rem', maxWidth: '40rem', margin: '0 auto' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', flexGrow: 1, width: '30rem', margin: '0 auto' }}>
                <div style={{ marginRight: '1rem' }}>
                    <Image src={ inviteInfo.data.guild.iconUrl } alt="Discord guild icon" style={{ height: '170px', width: '170px' }} />
                </div>
                

                <div style={{ width: '10rem', flexGrow: 1 }}>
                    <p style={{ fontSize: '1.2em', color: '#dddddd', fontWeight: 'bold', marginBottom: 0 }}>Welcome to</p>
                    <Typography.Title style={{ marginBottom: '0.5rem' }}>{ inviteInfo.data.guild.name }</Typography.Title>

                    <div><UserOutlined /> { inviteInfo.data.guild.memberCount } members</div>

                    <p style={{ margin: '1rem 0', fontSize: '1.2em' }}>{ inviteInfo.data.guild.description }</p>
                </div>
            </div>

            <div style={{ textAlign: 'center' }}>
                { (inviteInfo.data.guild.captcha && !captchaResponse) && <div style={{ marginTop: '2rem' }}><HCaptcha sitekey={process.env.REACT_APP_HCAPTCHA_KEY} theme="dark" onVerify={(response) => setCaptchaResponse(response)} /></div> }
                <Button style={{marginTop: '1.5rem'}} onClick={handleJoinGuild} type="primary" block size="large" disabled={ inviteInfo.data.guild.captchat && !captchaResponse }>Join Discord Server</Button>
            </div>
        </div>
    );

    /*
    <Container className="m-0 p-0">
            <h1>Captcha</h1>

            { inviteUrl ?
                <Alert variant="success">
                    <Alert.Heading>Success</Alert.Heading>
                    <Button href={inviteUrl} size="lg">Join</Button>
                </Alert>
            : (error ? 
                <Alert variant="danger">
                    <Alert.Heading>Error</Alert.Heading>
                    { error }
                </Alert>
            :
                <>
                    <p className="lead">
                        Please tick the box below to join the guild
                    </p>

                    
                </>
            )
            }
        </Container>
        */
}


export default Invite;