import React, { useEffect } from 'react';
import axios from '../../axios';
import { useQuery, useQueryClient } from 'react-query';
import { Switch, Route, Redirect, Link, useLocation, useParams, useRouteMatch, useHistory } from "react-router-dom";
import { Image, Typography, Space, Divider, Row, Col, Card, Menu, Skeleton, Grid, message } from 'antd';
import { UserOutlined, EyeOutlined } from '@ant-design/icons';
import { Helmet } from "react-helmet";

import GuildMember from './GuildMember';
import Admin from './Admin/Admin';
import Commands from './Commands';
import Leaderboard from './Leaderboard';
import MusicPlayer from './MusicPlayer';
import websocket from '../websocket';

const menuItems = ['admin', 'commands', 'leaderboard', 'music'];

function Guild({ setNavbar, developerMode }) {
    const screens = Grid.useBreakpoint();
    const { guildId } = useParams();
    const { url: baseUrl } = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const queryClient = useQueryClient();

    const guildInfo = useQuery(['/guild', guildId], () => axios.get('/guild', { headers: { Guild: guildId } }), {
        onError: () => {
            message.error("Couldn't fetch guild.");
            history.push('/');
        }
    });
    const { data: guildData } = guildInfo;
    
    useEffect(() => {
        if (!(guildData && location)) return setNavbar(null);

        let selectedKeys = menuItems.filter(item => location.pathname.startsWith(`${baseUrl}/${item}`));
        if (selectedKeys.length === 0) selectedKeys.push('guild');

        setNavbar(
            <Menu theme="dark" mode={ screens.lg ? 'horizontal' : 'vertical' } style={{ flexGrow: 1 }} selectedKeys={selectedKeys}>
                <Menu.Item key="guild"><Link to={baseUrl}>{guildData.name}</Link></Menu.Item>

                { guildData.options.modules.admin && <Menu.Item key="admin"><Link to={developerMode ? `${baseUrl}/admin/developer` : `${baseUrl}/admin`}>Admin</Link></Menu.Item> }
                <Menu.Item key="commands"><Link to={`${baseUrl}/commands`}>Commands</Link></Menu.Item>
                { guildData.options.modules.levelling && guildData.options.modules.levelling.functions.leaderboard && <Menu.Item key="leaderboard"><Link to={`${baseUrl}/leaderboard`}>Leaderboard</Link></Menu.Item> }
                { guildData.options.modules.music && <Menu.Item key="music"><Link to={`${baseUrl}/music`}>Music</Link></Menu.Item> }
            </Menu>
        );

        return () => setNavbar(null);
    }, [guildData, location, baseUrl, developerMode, setNavbar, screens])

    useEffect(() => {
        axios.defaults.headers.common['Guild'] = guildId;
        return () => delete axios.defaults.headers.common['Guild'];
    }, [guildId]);

    useEffect(() => {
        websocket.emit('command', 'guild.subscribe', guildId);

        websocket.on('guild.updated', () => {
            queryClient.invalidateQueries(['/guild', guildId])
        });

        return () => {
            websocket.emit('command', 'guild.unsubscribe', guildId);
            websocket.off('guild.updated');
        };
    }, [queryClient, guildId]);

    useEffect(() => {
        websocket.on('guild.vanity.updated', newUrl => {
            window.location = location.pathname.replace(guildId, newUrl);
        });

        return (() => websocket.off('guild.vanity.updated'));
    }, [location, guildId]);

    if (!guildData) {
        return (
            <div style={{ padding: '2rem' }}>
                <Skeleton paragraph={{ rows: 8 }} />
            </div>
        );
    }
    return (
        <>
            <Helmet>
                <title>{guildData.name} | Mizar</title>
                <link rel="shortcut icon apple-touch-icon apple-touch-icon-precomposed" href={ guildData.iconUrl }></link>
            </Helmet>

            <Switch>
                <Route path={`${baseUrl}/admin`}>
                    { guildData.options.modules.admin ? <Admin guildName={guildData.name} guildId={guildData.id} guildOptions={guildData.options.modules.admin} developerMode={developerMode} /> : <Redirect to={`${baseUrl}`} /> }
                </Route>
                <Route path={`${baseUrl}/commands`}>
                    <Commands guild={guildData} />
                </Route>
                <Route path={`${baseUrl}/leaderboard`}>
                    { (guildData.options.modules.levelling && guildData.options.modules.levelling.functions.leaderboard) ? <Leaderboard guildId={guildId} /> :  <Redirect to={`${baseUrl}`} />}
                </Route>
                <Route path={`${baseUrl}/music`}>
                    { guildData.options.modules.music ? <MusicPlayer guildOptions={guildData.options.modules.music} guildId={guildId} /> :  <Redirect to={`${baseUrl}`} />}
                </Route>
                
                <Route exact path={`${baseUrl}`}>
                    <div style={{ padding: '2rem', maxWidth: '100rem', margin: '0 auto' }}>
                        <div style={{ marginBottom: '1rem' }}>
                            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }}>
                                <div style={{ display: 'flex', flexWrap: 'wrap', flexGrow: 1, width: '30rem' }}>
                                    <div style={{ marginRight: '1rem' }}>
                                        <Image src={ guildData.iconUrl } alt="Discord guild icon" style={{ height: '170px', width: '170px' }} />
                                    </div>
                                    

                                    <div style={{ width: '10rem', flexGrow: 1 }}>
                                        <p style={{ fontSize: '1.2em', color: '#dddddd', fontWeight: 'bold', marginBottom: 0 }}>Welcome to</p>
                                        <Typography.Title style={{ marginBottom: '0.5rem' }}>{ guildData.name }</Typography.Title>

                                        <Space split={<Divider type="vertical" />}>
                                            <div><UserOutlined /> { guildData.memberCount.all } members</div>
                                            <div><EyeOutlined /> { guildData.memberCount.online } online</div>
                                        </Space>

                                        <p style={{ margin: '1rem 0', fontSize: '1.2em' }}>{ guildData.options.description }</p>
                                    </div>
                                </div>

                                { guildData.member && <GuildMember guildMember={ guildData.member } /> }
                            </div>
                        </div>

                        <Typography.Title level={2}>Navigation</Typography.Title>
                        <Row gutter={16}>
                            { guildData.options.modules.admin && <Col xs={12} sm={6} style={{ marginBottom: '1rem' }}>
                                <Link to={`${baseUrl}/admin`}>
                                    <Card hoverable cover={<img alt="Admin" src="/images/admin.jpg" />}>
                                        <Card.Meta title="Admin" description="Configure Mizar's settings" />
                                    </Card>
                                </Link>
                            </Col> }
                            <Col xs={12} sm={6} style={{ marginBottom: '1rem' }}>
                                <Link to={`${baseUrl}/commands`}>
                                    <Card hoverable cover={<img alt="Commands" src="/images/commands.jpg" />}>
                                        <Card.Meta title="Commands" description="View all of my commands" />
                                    </Card>
                                </Link>
                            </Col>
                            { guildData.options.modules.levelling && guildData.options.modules.levelling.functions.leaderboard && <Col xs={12} sm={6} style={{ marginBottom: '1rem' }}>
                                <Link to={`${baseUrl}/leaderboard`}>
                                    <Card hoverable cover={<img alt="Leaderboard" src="/images/levelling.jpg" />}>
                                        <Card.Meta title="Leaderboard" description="Get the top members of the guild" />
                                    </Card>
                                </Link>
                            </Col> }
                            { guildData.options.modules.music &&  <Col xs={12} sm={6} style={{ marginBottom: '1rem' }}>
                                <Link to={`${baseUrl}/music`}>
                                    <Card hoverable cover={<img alt="Music" src="/images/music-player.jpg" />}>
                                        <Card.Meta title="Music" description="Manage my music player" />
                                    </Card>
                                </Link>
                            </Col> }
                        </Row>
                    </div>
                </Route>
                <Route>
                    <Redirect to={baseUrl} />
                </Route>
            </Switch>
        </>
    )
}

/*
<Route path={`${baseUrl}/analytics`}>
    { guild.options.modules.analytics ? <Analytics guildOptions={guild.options.modules.analytics} /> :  <Redirect to={`${baseUrl}`} />}
</Route>

{ guild.options.modules.analytics && 
    <Card as={Link} to={`${baseUrl}/analytics`}>
        <Card.Content>
            <Card.Header>Analytics</Card.Header>
            <Card.Description>Look at guild trends</Card.Description>
        </Card.Content>
    </Card>
}

{ guild.options.modules.analytics && <Menu.Item as={Link} to={`${baseUrl}/analytics`} active={location.pathname.startsWith(`${baseUrl}/analytics`)}>Analytics</Menu.Item> }
*/

export default Guild;