import React from 'react';
import { Typography, Button, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import GuildSelector from './GuildSelector';
import { description } from '../features';

function Invite({ authed, renderUnauthed, state, openAuthPopupWindow, handleLoginClick }) {
    return (
        <>
            <div style={{ padding: '2rem', maxWidth: '100rem', margin: '0 auto' }}>
                <div>
                    <Typography.Title>Add Mizar to Your Server Today</Typography.Title>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <img src="/logo-256.png" alt="Mizar's logo" style={{ marginRight: '1rem', marginBottom: '1rem', width: '170px', height: '170px' }} />
                        <div style={{fontSize: '1.4em', maxWidth: '60rem', flexGrow: 1}}>
                            { description }

                            <Space wrap style={{ marginTop: '1rem', marginBottom: '1rem', marginLeft: 0, marginRight: 0, display: 'block' }} direction="horizontal">
                                { !authed && 
                                    <>
                                        <Button type="primary" size="large" onClick={handleLoginClick} icon={<PlusOutlined />}>Login (recommended)</Button>
                                        <a href={`https://discord.com/api/oauth2/authorize?client_id=${ process.env.REACT_APP_DISCORD_ID }&redirect_uri=${ encodeURIComponent( process.env.REACT_APP_WEBPANEL_URL + "/authorise") }&response_type=code&scope=bot%20applications.commands%20identify%20guilds&permissions=8`}><Button size="large">OAuth Invite</Button></a>
                                        <a href={`https://discord.com/api/oauth2/authorize?client_id=${ process.env.REACT_APP_DISCORD_ID }&redirect_uri=${ encodeURIComponent( process.env.REACT_APP_WEBPANEL_URL + "/authorise") }&response_type=code&scope=bot%20applications.commands%20identify%20guilds&permissions=296318660343`}><Button size="large">OAuth With Custom Perms</Button></a>
                                    </>
                                }

                                <a href={process.env.REACT_APP_DOCS_URL+'/getting-started/permissions?utm_source=permissions&utm_medium=webpanel'} target="_blank" rel="noopener noreferrer"><Button size="large">View Permission Requirements</Button></a>
                            </Space>
                        </div>
                    </div>
                </div>
            </div>

            { authed ? <GuildSelector onlyShowGuildsToAdd={true} state={state} openAuthPopupWindow={openAuthPopupWindow} /> : <div style={{ maxWidth: '100rem', margin: '0 auto' }}>{ renderUnauthed() }</div> }
        </>
    ) 
}


export default Invite;