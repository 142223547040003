import React from 'react';
import { Typography, Avatar, List, Button, Alert, Space, Grid } from 'antd';
import ReactGA from 'react-ga';

const listings = {
    voting: [
        {
            name: 'Top.gg',
            favicon: '/images/bot-lists/topgg.png',
            url: 'https://top.gg/bot/705771937305067592',
            directUrl: 'https://top.gg/bot/705771937305067592/vote'
        },
        {
            name: 'Discord Labs',
            favicon: '/images/bot-lists/discord-labs.png',
            url: 'https://bots.discordlabs.org/bot/705771937305067592'
        },
        {
            name: 'Discords.com',
            favicon: '/images/bot-lists/discords.png',
            url: 'https://discords.com/bots/bot/705771937305067592',
            directUrl: 'https://discords.com/bots/bot/705771937305067592/vote'
        },
        {
            name: 'Discord Bot List',
            favicon: '/images/bot-lists/discord-bot-list.png',
            url: 'https://discordbotlist.com/bots/mizar',
            directUrl: 'https://discordbotlist.com/bots/mizar/upvote'
        },
        {
            name: 'Botlist Space',
            favicon: '/images/bot-lists/botlist-space.png',
            url: 'https://botlist.space/bot/705771937305067592',
            directUrl: 'https://botlist.space/bot/705771937305067592/upvote'
        },
        {
            name: 'Infinity Bot List',
            favicon: '/images/bot-lists/infinity-bot-list.png',
            url: 'https://infinitybotlist.com/bots/705771937305067592',
            directUrl: 'https://infinitybotlist.com/bots/705771937305067592/vote'
        },
        {
            name: 'Discord Botlist EU',
            favicon: '/images/bot-lists/discord-botlist-eu.png',
            url: 'https://discord-botlist.eu/bots/705771937305067592',
            directUrl: 'https://discord-botlist.eu/vote/705771937305067592'
        }
    ],
    review: [
        {
            name: 'Discord Extreme List',
            type: 'upvote/downvote',
            favicon: '/images/bot-lists/discord-extreme-list.png',
            url: 'https://discordextremelist.xyz/bots/705771937305067592',
        },
        {
            name: 'Bots on Discord',
            type: 'text',
            favicon: '/images/bot-lists/bots-on-discord.png',
            url: 'https://bots.ondiscord.xyz/bots/705771937305067592',
        },
        {
            name: 'Infinity Bot List',
            type: 'text',
            favicon: '/images/bot-lists/infinity-bot-list.png',
            url: 'https://infinitybotlist.com/bots/705771937305067592',
        }
    ],
    other: [
        {
            name: 'Discord Bots',
            favicon: '/images/bot-lists/discord-bots.png',
            url: 'https://discord.bots.gg/bots/705771937305067592',
        },
        {
            name: 'Disforge',
            favicon: '/images/bot-lists/disforge.png',
            url: 'https://disforge.com/bot/487-mizar',
        }
    ]
}

function quickVote() {
    if (process.env.REACT_APP_ANALYTICS_KEY) ReactGA.event({
        category: 'Voting',
        action: 'Quick Vote',
        transport: 'beacon'
    });
    listings.voting.forEach(listing => {
        window.open(listing.directUrl || listing.url, '_blank', 'noopener noreferrer');
    })
}

export default function Vote() {
    const breakpoints = Grid.useBreakpoint();

    return (
        <div style={{ padding: '2rem', maxWidth: '100rem', margin: '0 auto' }}>
            <Typography.Title>Bot Listings & Voting</Typography.Title>
            <Alert type="info" message="Voting for Mizar on bot listing sites helps me to grow to other servers. I would greatly appreciate it if you could vote for me - while it takes less than 60 seconds, it can help me a lot! 😃" showIcon={true} />

            <List itemLayout={ breakpoints.md ? 'horizontal' : 'vertical' } size="small" dataSource={listings.voting} renderItem={listing => {
                let actions = [
                    <a key="vote" href={listing.url} target="_blank" rel="noopener noreferrer"><Button>Vote</Button></a>
                ];
                if (listing.directUrl) actions.push(<a key="directVote" href={listing.directUrl} target="_blank" rel="noopener noreferrer"><Button>Direct Vote</Button></a>);

                return (
                    <List.Item actions={[<Space id="actions" direction="horizontal">{actions}</Space>]}>
                        <List.Item.Meta avatar={<Avatar src={listing.favicon} alt={`${listing.name} favicon`} />} title={listing.name} description={<span><a href={listing.url} target="_blank" rel="noopener noreferrer" style={{ wordBreak: 'normal', wordWrap: 'anywhere' }}>{listing.url}</a>{ listing.directUrl && <a href={listing.directUrl} target="_blank" rel="noopener noreferrer"> (direct)</a>}</span>} />
                    </List.Item>
                );  
            }} header={<div style={{ display: 'flex', alignItems: 'center', flexDirection: breakpoints.sm ? 'row' : 'column' }}>
                <Typography.Title level={2} style={{ marginRight: '1rem' }}>Voting Listings (24h)</Typography.Title>
                <Button type="primary" onClick={quickVote} style={{ marginLeft: 'auto' }}>Quick Vote (open all tabs)</Button>
            </div> } />

            
            <List itemLayout={ breakpoints.md ? 'horizontal' : 'vertical' } size="small" dataSource={listings.review} renderItem={listing => (
                <List.Item actions={[
                    <a key="review" href={listing.url} target="_blank" rel="noopener noreferrer"><Button>Review</Button></a>
                ]}>
                    <List.Item.Meta avatar={<Avatar src={listing.favicon} alt={`${listing.name} favicon`} />} title={`${listing.name} (${listing.type})`} description={<a href={listing.url} target="_blank" rel="noopener noreferrer" style={{ wordBreak: 'normal', wordWrap: 'anywhere' }}>{listing.url}</a>} />
                </List.Item>
            )} header={<Typography.Title level={2}>Review Listings</Typography.Title>} />

            <List itemLayout={ breakpoints.md ? 'horizontal' : 'vertical' } size="small" dataSource={listings.other} renderItem={listing => (
                <List.Item actions={[
                    <a key="visit" href={listing.url} target="_blank" rel="noopener noreferrer"><Button>Visit</Button></a>
                ]}>
                    <List.Item.Meta avatar={<Avatar src={listing.favicon} alt={`${listing.name} favicon`} />} title={listing.name} description={<a href={listing.url} target="_blank" rel="noopener noreferrer" style={{ wordBreak: 'normal', wordWrap: 'anywhere' }}>{listing.url}</a>} />
                </List.Item>
            )} header={<Typography.Title level={2}>Other Listings</Typography.Title>} />

            <p style={{ fontSize: '1.5em', marginTop: '1rem' }}>Thank You.</p>
        </div>
    );
}