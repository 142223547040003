import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { Table, message, Grid, Tag } from 'antd';

function ArgumentTypeTag({ type }) {
    switch (type) {
    case 'STRING':
        return <Tag color="magenta">String</Tag>;
    case 'INTEGER':
        return <Tag color="red">Integer</Tag>;
    case 'BOOLEAN':
        return <Tag color="volcano">Boolean</Tag>;
    case 'MEMBER':
        return <Tag color="lime">Member</Tag>;
    case 'CHANNEL':
        return <Tag color="cyan">Channel</Tag>;
    case 'ROLE':
        return <Tag color="green">Role</Tag>;
    case 'MESSAGE':
        return <Tag color="blue">Message</Tag>;
    case 'EMOJI':
        return <Tag color="geekblue">Emoji</Tag>;
    case 'TIME':
        return <Tag color="orange">Time</Tag>;
    case 'TIMEZONE':
        return <Tag color="gold">Timezone</Tag>;
    case 'VOICE':
        return <Tag color="gold">Voice</Tag>;
    case 'COLOUR':
        return <Tag color="purple">Colour</Tag>;
    case 'DURATION':
        return <Tag color="orange">Duration</Tag>;
    default:
        return <Tag color="magenta">{type}</Tag>;
    }
}

export default function CommandsTable({ guildModules }) {
    const commandsData = useQuery('/commands', { onError: () => message.error("Commands couldn't be fetched.") });
	const commands = useMemo(() => {
		if (!commandsData.data) return {
			rows: [],
			count: 0,
		};

		const tableRows = [];
		let count = 0;

		commandsData.data.modules.forEach(module => {
            if (module.functions.length === 0) return;
			if (guildModules && (!guildModules[module.code] || Object.keys(guildModules[module.code]).length === 0)) return;

            tableRows.push({
                key: module.name,
                header: true,
                name: module.name,
                length: module.functions.length,
                description: module.description
            });

            module.functions.forEach((func, i) => {
				const guildCommand = guildModules?.[module.code].functions[func.code];
				if (guildModules && !guildCommand) return;

                tableRows.push({
                    key: module.name + i,
                    command: guildCommand ? (func.argumentsStrings ? func.argumentsStrings.map(argumentString => `${guildCommand.primary} ${argumentString}`) : [`${guildCommand.primary}`]) : (func.argumentsStrings ? func.argumentsStrings.map(argumentString => `!${func.command} ${argumentString}`) : [`!${func.command}`]),
                    description: func.description,
					guildCommand,
                    func,
                });

				count += 1;
            });
        });

        return {
            rows: tableRows,
            count: count
        }
	}, [commandsData, guildModules]);

    const breakpoint  = Grid.useBreakpoint();

    return (
		<Table dataSource={commandsData.isSuccess ? commands.rows : []} locale={{ emptyText: 'No Commands Found' }} loading={!commandsData.isSuccess} pagination={false} size="small" expandable={{
			expandedRowRender: row => <div>
				<div>
					<b>Command:</b> { row.func.slashCommand && <span><pre style={{ display: 'inline' }}>/{row.func.slashCommand}</pre> or </span> }<pre style={{ display: 'inline' }}>{row.guildCommand?.primary || '!' + row.func.command}</pre>{(row.guildCommand?.aliases || row.func.aliases).map(alias => <span key={alias}>/<pre style={{ display: 'inline' }}>{row.guildCommand ? '' : '!'}{alias}</pre></span>)}
					<p style={{ fontStyle: 'italic' }}>{row.description}</p>
				</div>
		
				{ row.func.arguments &&
					<div>
						<b>Arguments:</b>
						<ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
							{ row.func.arguments.map(arg => <li key={arg.name} style={{ marginTop: '0.1rem' }}>
								<ArgumentTypeTag type={arg.type} />{arg.name}{arg.required && <span style={{ color: 'red' }}>*</span>} - {arg.description}
							</li> )}
						</ul>
					</div>
				}
			</div>,
			rowExpandable: row => !row.header
		}}>
			<Table.Column title={commandsData.isSuccess ? `Command (${commands.count})` : 'Command'} dataIndex="command" key="command" render={(value, row) => {
				if (row.header) return ({
					children: breakpoint.sm ? <p style={{ fontSize: '1.1em', margin: '0.7rem 0 0 0' }}><strong>{row.name} Module ({row.length})</strong> - <span>{row.description}</span></p> : <p style={{ fontSize: '1.1em', margin: '0.7rem 0 0 0' }}><strong>{row.name} Module ({row.length})</strong><br /><span>{row.description}</span></p>,
					props: {
						colSpan: 3
					}
				});

				return <span>{value && value.map(val => <span style={{ display: 'block' }} key={val}>{val}</span>)}</span>;
			}} />
			<Table.Column title="Description" dataIndex="description" key="description" render={(value, row) => {
				if (row.header) return { props: { colSpan: 0 }};
				return <span>{value}</span>;
			}} responsive={["md"]} />
		</Table>
    );
}