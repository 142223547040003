import React, { useState, useMemo, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import axios from '../../../axios';
import { Typography, Form, Skeleton, Button, Modal, message, Avatar, Space, Divider } from 'antd';
import { DeleteOutlined, SendOutlined } from '@ant-design/icons';
import { LoadingDimmer } from '../../Components';
import { ClosePrompt, SaveSettingsDialog } from './FormElements';
import OptionFields, { findSettingsChanges } from './OptionFields';
import ReactGA from 'react-ga';

function GeneralSettings({ guildName, channels, roles, setOnboard, guildId, guildContext }) {
	const queryClient = useQueryClient();
	const generalSettingsInfo = useQuery(['/administration/settings', guildId]);
	const extraUsersInfo = useQuery(['/administration/extra-users', guildId]);
	const { data: settingsData } = generalSettingsInfo;

	const [ restrictionChanges, setRestrictionChanges ] = useState({});

	const initialSettings = useMemo(() => (settingsData && settingsData.options) || {}, [settingsData]);
	const schema = useMemo(() => {
		if (!settingsData) return [];

		let _schema = {};
		for (let [fieldId, fieldSchema] of Object.entries(settingsData.schema)) {
			if (!fieldSchema.position) continue;

			let row = _schema[fieldSchema.position] || [];
			row.push({ ...fieldSchema, id: fieldId });
			_schema[fieldSchema.position] = row;
		}
		return Object.values(_schema).sort((a, b) => a[0].position - b[0].position);
	}, [settingsData]);

	const [ loading, setLoading ] = useState(false);
	const [ changed, setChanged ] = useState(0);
	const [ form ] = Form.useForm();

	useEffect(() => {
		form.resetFields();
		setChanged(0);
	}, [initialSettings, form]);

	function cancelChanges() {
		form.resetFields();
		setRestrictionChanges({});
		setChanged(0);
	}
	function submitChanges(values) {
		if (restrictionChanges && Object.keys(restrictionChanges).length > 0) values._restrictions = restrictionChanges;

		let changes = findSettingsChanges(initialSettings, values);
		if (!changes) return cancelChanges();

		if (process.env.REACT_APP_ANALYTICS_KEY) ReactGA.event({
			category: 'Admin Panel',
			action: 'Save General Settings',
			transport: 'beacon'
		});

		setLoading(true);
		axios.put(`/administration/settings`, { changes }).then(data => {
			queryClient.setQueryData([`/administration/settings`, guildId], data);
			if (restrictionChanges && Object.keys(restrictionChanges).length > 0) queryClient.invalidateQueries(['/administration/restrictions', guildId]);
			message.info('Settings saved');
			cancelChanges();
			setLoading(false);
		}).catch(error => {
			if (error.response && error.response.data && error.response.data.errors) message.error(error.response.data.errors.join('\n'));
			else message.error('An error occured');

			window.scrollTo(0, 0);
			setLoading(false);
		});
	}
	
	function resetToDefault() {
		if (process.env.REACT_APP_ANALYTICS_KEY) ReactGA.event({
			category: 'Admin Panel',
			action: 'Reset General Settings',
			transport: 'beacon'
		});

		setLoading(true);
		axios.delete(`/administration/settings`).then(data => {
			queryClient.setQueryData([`/administration/settings`, guildId], data);
			queryClient.invalidateQueries(['/administration/restrictions', guildId]);
			message.info('Settings reset');
			setLoading(false);
		}).catch(() => {
			message.error('An error occured');
			setLoading(false);
		});
	}

	function onResetClicked() {
		Modal.confirm({
			title: 'Reset Guild Settings to Default',
			content: 'Are you sure you want to completely reset your configuration, including any modules settings? This action cannot be undone.',
			okText: 'Delete',
			okType: 'danger',
			icon: <DeleteOutlined />,
			onOk: resetToDefault
		});
	}

	function onTestAdminMessageClicked() {
		Modal.confirm({
			title: 'Send Test Bot Admin Message',
			content: 'Are you sure you want to send a test bot admin message?',
			okText: 'Send',
			okType: 'primary',
			icon: <SendOutlined />,
			onOk: sendAdminMessage
		});
	}

	function sendAdminMessage() {
		setLoading(true);
		axios.post(`/administration/admin-message`).then(data => {
			message.info((data.data && data.data.message) || 'Message could not be sent');
			setLoading(false);
		}).catch(() => {
			message.error('An error occured');
			setLoading(false);
		});
	}

	function setRestrictionDefinition(name, definition) {
		const _changes = Object.assign({}, restrictionChanges);
		_changes[name] = definition;
		setChanged(i => i + 1);
		setRestrictionChanges(_changes);
	}

	return (
		<div style={{ padding: '1rem 2rem' }}>
			{ generalSettingsInfo.isSuccess && <div style={{ float: 'right' }}>
				<Button danger onClick={onTestAdminMessageClicked} type="primary" style={{ margin: '0.5rem' }}>Send Test Admin Message</Button>
				<Button danger onClick={onResetClicked} type="primary" style={{ margin: '0.5rem' }}>Reset to Default</Button>
			</div>
			 }
			<Typography.Title level={2}>{guildName} General Settings</Typography.Title>

			{ changed ? <ClosePrompt /> : null }
			{ loading && <LoadingDimmer /> }

			{ !generalSettingsInfo.isSuccess ? <><Skeleton active /><Skeleton active /><Skeleton active /><Skeleton active /><Skeleton active /></> :
				<>
					<Form layout="vertical" form={form} onValuesChange={() => setChanged(i => i + 1)} onFinish={submitChanges} initialValues={initialSettings} requiredMark={false} scrollToFirstError>
						{ changed ? <SaveSettingsDialog cancelChanges={cancelChanges} /> : null }
						<OptionFields schema={schema} guildContext={guildContext} setRestrictionDefinition={setRestrictionDefinition} restrictionChanges={restrictionChanges} form={form} updateCount={changed} overrideDisabled={true} />
					</Form>

					{ extraUsersInfo && extraUsersInfo.isSuccess && extraUsersInfo.data && extraUsersInfo.data.users.length > 0 &&
						<>
							<Typography.Title level={3} style={{ marginBottom: 0, marginTop: '1rem' }}>Additional Users</Typography.Title>
							<p style={{fontSize: '1em', marginTop: 0}}>I offer {extraUsersInfo.data.users.length} extra user{extraUsersInfo.data.users.length > 1 ? 's' : ''}, which allow{extraUsersInfo.data.users.length > 1 ? '' : 's'} me to play music/speak in multiple channels at once. This is an entirely optional feature.</p>

							<Space direction="horizontal" wrap={true} split={<Divider type="vertical" />}>
								{ extraUsersInfo.data.users.map(user => (
									<div style={{ display: 'flex', alignItems: 'center' }} key={user.id}>
										<Avatar src={user.avatarUrl} alt="Extra user avatar" />
										<p style={{ marginBottom: 0, marginLeft: '0.4rem', fontSize: '1.1em' }}>{user.name}</p>
										{ user.added ?
											<p style={{ marginBottom: 0, marginLeft: '0.4rem', fontSize: '0.8em', fontWeight: 'bold', color: '#449e29' }}>ADDED</p>
										:
											<Button style={{ marginLeft: '0.4rem' }} size="small" type="primary" onClick={() => window.open(`https://discord.com/api/oauth2/authorize?client_id=${user.id}&scope=bot&disable_guild_select=true&permissions=3146752&guild_id=${guildId}`, '_blank', 'width=500, height=800, menubar=no, toolbar=no')}>Add</Button>
										}
									</div>
								))}
							</Space>
						</>
					}
				</>
			}
		</div>
	);
}

export default GeneralSettings;