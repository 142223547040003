import React, { useMemo } from 'react';
import { Row, Col, InputNumber, Input, Form, Switch, Typography } from 'antd';
import { ColourField, TimezoneField, SelectionSlider, ArrayField, ChannelField, RoleField, InputPercentage, UserField, ErrorResponses, BooleanGroup } from './FormElements';
import Restrictions from './Restrictions';
import Docs from '../../Docs';

export default React.memo(function OptionFields({ schema, functionId, guildContext, setRestrictionDefinition, restrictionChanges, form, updateCount, overrideDisabled }) {
	let allDisabled = false;

	if (!overrideDisabled) {
		allDisabled = !form.getFieldValue('enabled');
		if (!allDisabled && functionId) allDisabled = !form.getFieldValue(['functions', functionId, 'enabled']);
	}

	return (
		<>
			{ schema.map(row => {
				if (row.length > 1) {
					let defaultSpan = Math.floor(24/row.length);

					return (
						<Row gutter={16} key={row[0].id}>
							{ row.map(field => (
								<Col key={field.id} span={field.span || defaultSpan}>
									<OptionField schema={field} key={functionId + field.id} functionId={functionId} guildContext={guildContext} setRestrictionDefinition={setRestrictionDefinition} restrictionChanges={restrictionChanges} form={form} allDisabled={allDisabled} updateCount={updateCount} />
								</Col>
							))}
						</Row>
					);
				}
				return <OptionField schema={row[0]} key={functionId + row[0].id} functionId={functionId} guildContext={guildContext} setRestrictionDefinition={setRestrictionDefinition} restrictionChanges={restrictionChanges} form={form} allDisabled={allDisabled} updateCount={updateCount} />
			})}
		</>
	);
}, (prevProps, newProps) => {
	if (prevProps.guildContext !== newProps.guildContext) return false;
	if (prevProps.restrictionChanges !== newProps.restrictionChanges) return false;
	if (prevProps.updateCount !== newProps.updateCount) return false;
	return true;
});

const OptionField = React.memo(function OptionField({ schema, functionId, guildContext, setRestrictionDefinition, restrictionChanges, form, allDisabled }) {
	/*
	Check if disabled
	*/

	let disabled = false;

	// Check if module/function is enabled
	if (schema.id !== 'enabled' && allDisabled) disabled = true;
	else if (schema.id === 'enabled' && functionId) {
		if (!form.getFieldValue('enabled')) disabled = true;
	}

	// Check for other dependencies
	if (schema.dependsOn && !disabled) {
		for (let dependency of schema.dependsOn) {
			if (functionId) {
				if (dependency.startsWith('#')) {
					if (!form.getFieldValue(dependency.slice(1))) {
						disabled = true;
						break;
					}
				}
				else if (!form.getFieldValue(['functions', functionId, dependency])) {
					disabled = true;
					break;
				}
			}
			else if (!form.getFieldValue(dependency)) {
				disabled = true;
				break;
			}
		}
	}

	const { field, valuePropName } = useMemo(() => {
		let field, valuePropName;

		switch (schema.type) {
			case 'boolean':
				field = <Switch disabled={disabled || !schema.editable} />
				valuePropName = 'checked';
				break;

			case 'string':
				field = <Input placeholder={schema.placeholder || "None"} minLength={schema.min} maxLength={schema.max} disabled={disabled || !schema.editable} prefix={schema.prefix} />;
				break;

			case 'text':
				field = <Input.TextArea placeholder="None" minLength={schema.min} maxLength={schema.max} disabled={disabled || !schema.editable} />
				break;

			case 'integer':
				field = <InputNumber placeholder="None" min={schema.min || 0} max={schema.max} disabled={disabled || !schema.editable} style={{ width: '100%' }} />;
				break;

			case 'percentage':
				field = <InputPercentage disabled={disabled || !schema.editable} />;
				break;

			case 'colour':
				field = <ColourField disabled={disabled || !schema.editable} />;
				break;

			case 'timezone':
				field = <TimezoneField placeholder="Europe/London" disabled={disabled || !schema.editable} />;
				break;
				
			case 'selection':
				if (schema.selectionSlider) {
					field = <SelectionSlider schema={schema} disabled={disabled || !schema.editable} />;
				}
				break;

			case 'booleanGroup':
				field = <BooleanGroup booleans={schema.booleans} groups={schema.groups} disabled={disabled || !schema.editable} />;
				break;
			
			case 'array':
				field = <ArrayField name={functionId ? ['functions', functionId, schema.id] : schema.id} guildContext={guildContext} min={schema.min} max={schema.max} schema={schema.arraySchema} disabled={disabled || !schema.editable} />;
				break;

			case 'command':
				field = <Input placeholder="None" minLength={schema.min} maxLength={schema.max} disabled={disabled || !schema.editable} addonBefore={guildContext.mainPrefix} />;
				break;

			case 'channel':
				field = <ChannelField channels={guildContext.channels} type={schema.channelType} canSend={schema.canSend} canSpeak={schema.canSpeak} nullable={schema.nullable} disabled={disabled || !schema.editable} />;
				break;

			case 'role':
				field = <RoleField roles={guildContext.roles} nullable={schema.nullable} disabled={disabled || !schema.editable} />;
				break;

			case 'user':
				field = <UserField nullable={schema.nullable} disabled={disabled || !schema.editable} />;
				break;

			case 'restrictions':
				field = <Restrictions setRestrictionDefinition={setRestrictionDefinition} restrictionChanges={restrictionChanges} guildContext={guildContext} disabled={disabled || !schema.editable} />;
				break;

			case 'errorResponses':
				field = <ErrorResponses errors={schema.errors} disabled={disabled || !schema.editable} />;
				break;

			case 'heading':
				break;
				
			default:
				field = <p>Invalid schema type {schema.type}</p>;
		}
		return { field, valuePropName };
	}, [schema, guildContext, functionId, disabled, restrictionChanges, setRestrictionDefinition]);

	const rules = useMemo(() => {
		const rules = [];

		if (!schema.nullable) rules.push({ required: true, message: 'Required' });

		let itemName = ' characters';
		if (schema.type === 'integer') itemName = '';

		if (schema.percentage) {
			rules.push({ min: 0, message: `Must be more than 0%` });
			rules.push({ max: 100, message: `Must be more than 100%` });
		}
		else if (schema.type !== 'array') {
			if (schema.min) rules.push({ min: schema.min, message: `Must be more than ${schema.min}${itemName}` });
			if (schema.max) rules.push({ max: schema.max, message: `Must be more than ${schema.max}${itemName}` });
		}

		if (schema.requiredRegex) rules.push({ pattern: schema.requiredRegex, message: 'Must meet the field requirements' });
		if (schema.regexFilter) rules.push({ validator: ((r, value) => new Promise((resolve, reject) => (new RegExp(schema.regexFilter)).test(value) ? reject(new Error('Must meet the field requirements')) : resolve()))});

		if (schema.mustInclude) {
			for (let string of schema.mustInclude) {
				if (string.includes('|')) rules.push({ validator: (_, value) => new Promise((resolve, reject) => (!value || string.split('|').find(str => value.includes(str))) ? resolve() : reject(new Error(`Must include ${string}`)))});
				else rules.push({ validator: (_, value) => new Promise((resolve, reject) => (!value || value.includes(string)) ? resolve() : reject(new Error(`Must include ${string}`)))});
			}
		}
	}, [schema]);

	const dependencies = useMemo(() => 
		schema.dependsOn ? schema.dependsOn.map(dependency => {
			if (functionId) {
				if (dependency.startsWith('#')) return dependency.slice(1);
				else return (['functions', functionId, dependency]);
			}
			else return dependency;
		}) : []
	, [schema, functionId]);

	if (field) {
		return (
			<Form.Item name={functionId ? ['functions', functionId, schema.id] : schema.id} label={schema.title} tooltip={schema.tooltip} valuePropName={valuePropName || 'value'} disabled={disabled} required={!schema.nullable} rules={disabled  ? [] : rules} dependencies={dependencies} extra={(schema.warning || schema.docsPath) && <>
				{ schema.docsPath && <Docs path={schema.docsPath} type="button" float={schema.warning} />}
				{ schema.warning && <p style={{ color: '#bb3939', fontWeight: 'bold', lineHeight: '1.2em', fontSize: '0.9em' }}>Warning: {schema.warning}</p>}
			</>}>
				{field} 
			</Form.Item>
		);
	}
	else if (schema.type === 'heading') {
		return (
			<>
				<Typography.Title level={functionId ? 4 : 3} style={{ marginBottom: schema.tooltip ? '0rem' : '1rem', marginTop: '1rem' }}>{schema.title}</Typography.Title>
				{ schema.tooltip && <p style={{fontSize: '1em', marginTop: 0}}>{schema.tooltip}</p> }
			</>
		);
	}
	else {
		return (
			<p>{schema.title}</p>
		);
	}
}, (prevProps, newProps) => {
	if (prevProps.guildContext !== newProps.guildContext) return false;
	if (newProps.schema.type === 'restrictions' && prevProps.restrictionChanges !== newProps.restrictionChanges) return false;
	if (prevProps.allDisabled !== newProps.allDisabled) return false;
	if ((newProps.schema.dependsOn || newProps.schema.id === 'enabled') && prevProps.updateCount !== newProps.updateCount) return false;
	return true;
});

export function findSettingsChanges(originalSettings, newSettings) {
	const changedSettings = {};

	for (const [key, value] of Object.entries(newSettings)) {
		if (key === 'functions') {
			for (const [funcKey, funcValue] of Object.entries(value)) {
				if (originalSettings.functions[funcKey] !== undefined) {
					for (const [funcSettingsKey, funcSettingsValue] of Object.entries(funcValue)) {
						if (originalSettings.functions[funcKey][funcSettingsKey] === undefined || originalSettings.functions[funcKey][funcSettingsKey] !== funcSettingsValue) {
							if (!changedSettings.functions) changedSettings.functions = {};
							if (!changedSettings.functions[funcKey]) changedSettings.functions[funcKey] = {};
							changedSettings.functions[funcKey][funcSettingsKey] = newSettings.functions[funcKey][funcSettingsKey];
						}
					}
				}
			} 
		}
		else {
			if (originalSettings[key] === undefined || originalSettings[key] !== value) changedSettings[key] = newSettings[key];
		}
	}
	
	if (Object.values(changedSettings).length === 0) return null;
	return changedSettings;
}