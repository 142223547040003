import React, { useEffect, useState, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import axios from '../../../axios';

import { Typography, Collapse, Skeleton, Divider, Button, Modal, Radio, Input, Form, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { LoadingDimmer } from '../../Components';
import { ClosePrompt, SaveSettingsDialog } from './FormElements';
import Restrictions from './Restrictions';
import ReactGA from 'react-ga';
import Docs from '../../Docs';

const CustomCommand = React.memo(function CustomCommand({ commandId, commandName, guildContext }) {
	const queryClient = useQueryClient();
	const customCommandInfo = useQuery([`/administration/commands/${commandId}`, guildContext.id], () => {
		if (commandId === 'new') return {
			data: {
				trigger: {
					type: 'command'
				},
				action: {
					type: 'command'
				}
			}
		};

		return axios.get(`/administration/commands/${commandId}`);
	});
	const initialValues = useMemo(() => customCommandInfo.data || {}, [customCommandInfo]);

	const [ changed, setChanged ] = useState(0);
	const [ form ] = Form.useForm();

	const [ loading, setLoading ] = useState(false);
	const [ triggerType, setTriggerType ] = useState('command');
	const [ actionType, setActionType ] = useState('command');
	const [ restrictionChanges, setRestrictionChanges ] = useState({});

	useEffect(() => {
		form.resetFields();
		setTriggerType((initialValues && initialValues.trigger) ? initialValues.trigger.type : 'command');
		setActionType((initialValues && initialValues.action) ? initialValues.action.type : 'command');
		setChanged(0);
	}, [initialValues, form]);

	function deleteCommand() {
		if (process.env.REACT_APP_ANALYTICS_KEY) ReactGA.event({
			category: 'Admin Panel',
			action: 'Delete Custom Command',
			transport: 'beacon'
		});

		setLoading(true);
		axios.delete(`/administration/commands/${commandId}`).then(data => {
			queryClient.setQueryData([`/administration/commands`, guildContext.id], { data: data.data.commands });
			queryClient.invalidateQueries(['/administration/restrictions', guildContext.id]);
			message.info('Command removed');
			setLoading(false);
		}).catch(() => {
			message.error('An error occured');
			setLoading(false);
		});
	}
	function onDeleteClicked() {
		Modal.confirm({
			title: 'Delete Custom Command',
			content: `Are you sure you want to delete ${commandName}? This action cannot be undone.`,
			okText: 'Delete',
			okType: 'danger',
			icon: <DeleteOutlined />,
			onOk: deleteCommand
		});
	}

	function submitChanges(values) {
		if (process.env.REACT_APP_ANALYTICS_KEY) ReactGA.event({
			category: 'Admin Panel',
			action: 'Save Custom Command',
			transport: 'beacon'
		});

		setLoading(true);

		let data = { command: values };
		if (restrictionChanges) data._restrictions = restrictionChanges;

		axios.post(`/administration/commands/${commandId}`, data).then(data => {
			queryClient.setQueryData([`/administration/commands/${commandId}`, guildContext.id], { data: data.data.command });
			if (restrictionChanges) queryClient.invalidateQueries(['/administration/restrictions', guildContext.id]);
			if (data.data.commands) queryClient.setQueryData([`/administration/commands`, guildContext.id], { data: data.data.commands });

			cancelChanges();
			if (commandId === 'new') {
				message.info('Command created');
				queryClient.invalidateQueries([`/administration/commands/new`, guildContext.id]);
			}
			else message.info('Command updated');
			setLoading(false);
		}).catch((error) => {
			if (error.response && error.response.data && error.response.data.errors) message.error(error.response.data.errors.join(<br />));
			else message.error('An error occured');
			setLoading(false);
		});
	}
	function cancelChanges() {
		form.resetFields();
		setRestrictionChanges({});
		setChanged(0);
	}

	function setRestrictionDefinition(name, definition) {
		const _changes = Object.assign({}, restrictionChanges);
		_changes[name] = definition;
		setChanged(i => i + 1);
		setRestrictionChanges(_changes);
	}

	if (!customCommandInfo.isSuccess) return <><Skeleton active /><Skeleton active /></>

	return (
		<Form layout="vertical" form={form} onValuesChange={() => setChanged(true)} onFinish={submitChanges} initialValues={initialValues} requiredMark={false} scrollToFirstError={true}>
			{ commandId !== 'new' && <Button danger onClick={onDeleteClicked} type="primary" style={{ float: 'right' }}>Delete</Button> }
			{ changed ? <ClosePrompt /> : null }
			{ changed ? <SaveSettingsDialog cancelChanges={cancelChanges} /> : null}
			{ loading && <LoadingDimmer /> }

			<Typography.Title level={5}>Trigger</Typography.Title>
			<Form.Item name={['trigger', 'type']} label="Trigger Type" required>
				<Radio.Group onChange={e => setTriggerType(e.target.value)}>
					<Radio.Button value="command">Command</Radio.Button>
					<Radio.Button value="phrase">Phrase</Radio.Button>
					<Radio.Button value="regex">Regex</Radio.Button>
				</Radio.Group>
			</Form.Item>

			{ triggerType === 'command' && <Form.Item name={['trigger', 'command']} label="Trigger Command" required extra={<p>Treated as a regular command and will return errors as normal. Can be used to prefill existing command arguments, e.g. '!playlist' can be a shortcut for '!play Faded'</p>} rules={[
				{ required: true, message: 'Required' },
				{ min: 1, message: 'Must be more than 1 character' },
				{ max: 100, message: 'Must be less than 100 characters' }
			]}>
				<Input type="text" placeholder="helloworld" maxLength={100} />
			</Form.Item> }

			{ triggerType === 'phrase' && <Form.Item name={['trigger', 'phrase']} label="Trigger Phrase" required extra={<p>Will trigger if any user sends a message containing this case-sensitive phrase. Use * and ? as wildcards</p>} rules={[
				{ required: true, message: 'Required' },
				{ min: 1, message: 'Must be more than 1 character' },
				{ max: 100, message: 'Must be less than 100 characters' }
			]}>
				<Input type="text" placeholder="hello*world" maxLength={100} />
			</Form.Item> }

			{ triggerType === 'regex' && <Form.Item name={['trigger', 'regex']} label="Trigger Regex" required extra={<p>Will be compared against every user message. See <a href="https://regexr.com" target="_blank" rel="noreferrer">Regexr</a> for more details and to test your Regex</p>} rules={[
				{ required: true, message: 'Required' },
				{ min: 1, message: 'Must be more than 1 character' },
				{ max: 100, message: 'Must be less than 100 characters' },
				{ type: 'regexp', message: 'Must be valid regex' }
			]}>
				<Input type="text" placeholder="/helloworld/gi" maxLength={100} />
			</Form.Item> }

			<Divider />

			<Typography.Title level={5}>Action</Typography.Title>
			<Form.Item name={['action', 'type']} label="Action Type" required>
				<Radio.Group onChange={e => setActionType(e.target.value)}>
					<Radio.Button value="command">Command</Radio.Button>
					<Radio.Button value="message">Message</Radio.Button>
				</Radio.Group>
			</Form.Item>

			{ actionType === 'command' && <Form.Item name={['action', 'command']} label="Action Command" required extra={<p>A Mizar command that will be run, without any prefix. Must include all arguments. No errors will be shown if the trigger is a phrase or regex</p>} rules={[
				{ required: true, message: 'Required' },
				{ min: 1, message: 'Must be more than 1 character' },
				{ max: 5000, message: 'Must be less than 5000 characters' }
			]}>
				<Input.TextArea allowClear autoSize={{ minRows: 2 }} maxLength={5000} placeholder="react -self hello world" />
			</Form.Item> }

			{ actionType === 'message' && <Form.Item name={['action', 'message']} label="Action Message" required extra={<p>A custom message that will be sent</p>} rules={[
				{ required: true, message: 'Required' },
				{ min: 1, message: 'Must be more than 1 character' },
				{ max: 300, message: 'Must be less than 300 characters' }
			]}>
				<Input type="text" placeholder="Hello World!" max={300} />
			</Form.Item> }

			<Divider />

			<Typography.Title level={5}>Restrictions</Typography.Title>
			<Form.Item name="restrictions" extra={<p>The command will only run if these restrictions are met. If the trigger is a phrase or regex, no restriction errors will be sent.</p>}>
				<Restrictions setRestrictionDefinition={setRestrictionDefinition} restrictionChanges={restrictionChanges} guildContext={guildContext} />
			</Form.Item>
		</Form>
	)
}); //

/*

			*/

function CustomCommands({ guildContext }) {
	const customCommandInfo = useQuery([`/administration/commands`, guildContext.id]);

	return (
		<div style={{ padding: '1rem 2rem' }}>
			<Typography.Title level={2}>Custom Commands<Docs path="/getting-started/custom-commands" /></Typography.Title>

			<p>Custom commands can be used to truly customize Mizar. Add your own responses to messages that match a phrase or regex filter and create short commands which can be used to prefill normal command arguments. Feel free to contact support if you have any issues!</p>

			<Collapse bordered={false}>
				<Collapse.Panel header="Triggers">
					<p>Triggers are the event that causes the custom command to run. They can be one of the following:</p>

					<Typography.Title level={5}>Command Triggers</Typography.Title>
					Treated as a regular command and will return errors as normal. Can be used to prefill existing command arguments, e.g. '!playlist' can be a shortcut for '!play Faded'

					<Typography.Title level={5}>Phrase Triggers</Typography.Title>
					Will trigger if any user sends a message containing a case-sensitive phrase. * and ? can be used as wildcards

					<Typography.Title level={5}>Regex Triggers</Typography.Title>
					Advanced use only - will trigger if a user sends a message that matches a Regular Expression. Please see <a href="https://regexr.com" target="_blank" rel="noreferrer">Regexr</a> for more details and to test your Regex
				</Collapse.Panel>
				<Collapse.Panel header="Actions">
					<p>Actions are the events that will be executed when the command is triggered:</p>
					<Typography.Title level={5}>Command Actions</Typography.Title>
					A Mizar command that will be run, without any prefix. Must include all arguments. No errors will be shown if the trigger is a phrase or regex

					<Typography.Title level={5}>Message Actions</Typography.Title>
					A custom message that will be sent
				</Collapse.Panel>
				<Collapse.Panel header="Restrictions">
					<p>Restrictions can be used to limit the custom command to a certain channel/user. They work in exactly the same way as other Mizar restrictions do, except no errors will be shown if the trigger is in phrase or regex mode</p>
				</Collapse.Panel>
			</Collapse>

			{ !customCommandInfo.isSuccess ? <><Skeleton active /><Skeleton active /><Skeleton active /></> : <>
				<Collapse defaultActiveKey={['new']} style={{ marginTop: '2rem' }}>
					{ customCommandInfo.data.map(command => (
						<Collapse.Panel header={command.name} key={command.id}>
							<CustomCommand commandId={command.id} commandName={command.name} guildContext={guildContext} />
						</Collapse.Panel>
					))}

					<Collapse.Panel header="Add New Command" key="new">
						<CustomCommand commandId="new" commandName="New Command" guildContext={guildContext} />
					</Collapse.Panel>
				</Collapse>
			</> }
		</div>
	);
}

export default CustomCommands;