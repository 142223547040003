import React, { useState, useEffect, useCallback } from 'react';
import { Layout, Menu, Button, Spin, Switch as RadioSwitch, Alert, message, Avatar, Grid, Tooltip } from 'antd';
import { LoginOutlined, WarningOutlined } from '@ant-design/icons';
import axios from '../axios';
import { Switch, Route, Redirect, Link, useLocation, useHistory } from "react-router-dom";
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { useQuery, useQueryClient } from 'react-query';

import About from './About';
import Changelog from './Changelog';
import Invite from './Invite';
import InviteMizar from './InviteMizar';
import GuildSelector from './GuildSelector';
import Guild from './Guild/Guild';
import Donate from './Donate';
import Vote from './Vote';
import Commands from './Commands';
//import Analytics from './Analytics';
import Footer from './Footer';
import styles from './App.module.css';
import socket from './websocket';

const mainMenu = ['about', 'invite', 'changelog', 'commands'];
function refresh() {
	window.location.reload();
}

function usePageAnalytics() {
	const location = useLocation();
	// const history = useHistory();

	/* 
	// Remove UTM referrer
	useEffect(() => {
		const query = new URLSearchParams(location.search);
		
		if (query.get('utm_source')) {
			query.delete('utm_source');
			query.delete('utm_medium');
			query.delete('utm_content');
			query.delete('utm_campaign');

			history.replace(location.pathname + '?' + query.toString());
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	*/

	useEffect(() => {
		if (process.env.REACT_APP_ANALYTICS_KEY) {
			ReactGA.set({ page: location.pathname });
			ReactGA.pageview( location.pathname );
		}
	}, [location]);
}

function App({ authDetails, setAuthDetails }) {
	const screens = Grid.useBreakpoint();
	const history = useHistory();
	const [ session, setSession ] = useState(null);

	const [ authLoading, setAuthLoading ] = useState(false);
	const [ authPopupWindow, setAuthPopupWindow ] = useState(null);
	const [ offline, setOffline ] = useState(false);

	const [ username, setUsername ] = useState(null);
	const [ userAvatar, setUserAvatar ] = useState(null);
	const [ developer, setDeveloper ] = useState(false);
	const [ developerMode, setDeveloperMode ] = useState(false);

	const [ navbar, setNavbar ] = useState(null);
	const { pathname } = useLocation();

	const queryClient = useQueryClient();
	useQuery(['/ping']);
	
	usePageAnalytics();

	const login = useCallback(() => {
		axios.post('/login', { token: localStorage.getItem('token') }, { timeout: 5000 }).then(response => {
			setSession(response.data.session);
			axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.session}`;

			if (response.data.user) {
				setUsername(response.data.user.username);
				setUserAvatar(response.data.user.avatar);
				setDeveloper(response.data.user.developer);
			}

			queryClient.invalidateQueries();
		}).catch(error => {
			if (error.code === 'ECONNABORTED') return setAuthLoading(false);
			message.error("Couldn't login to Mizar.");
		})
	}, [queryClient]);

	useEffect(() => {
		axios.interceptors.response.use(res => res, error => {
			if (!error || error.toString() === 'Error: Network Error' || error.code === 'ECONNABORTED') {
				setOffline(true);
				setUsername(null);
				message.destroy();
				message.error("Couldn't connect to Mizar's servers.");
			}
			else if (error.response && error.response.status === 401) {
				setUsername(null);
				message.destroy();
				message.error("Logged out.");
				setTimeout(refresh, 500);
			}
			else if (error.response && error.response.status === 403) {
				history.push('/');
			}

			return Promise.reject(error);
		});
	});

	useEffect(() => {
		function authorise(data) {
			axios.post('/authorise', data, { timeout: 5000, headers: { Authorization: `Bearer ${session}` }}).then(response => {
				if (response.data.user && response.data.token) {
					localStorage.setItem('token', response.data.token);

					if (response.data.guildId) history.push(`/g/${response.data.guildId}/admin/onboard`);
					setUsername(response.data.user.username);
					setUserAvatar(response.data.user.avatar);
					setDeveloper(response.data.user.developer);
				}
				else {
					message.error("Couldn't login to Mizar.");
					setTimeout(refresh, 2000);
				}
				setAuthLoading(false);
			}).catch(error => {
				if (error.code === 'ECONNABORTED') return setAuthLoading(false);

				message.error("Couldn't login to Mizar.");
				setTimeout(refresh, 2000);
				setAuthLoading(false);
			});
		}

		function authoriseFromPopup(event) {
			if (event.origin === process.env.REACT_APP_WEBPANEL_URL && event.data && event.data.type === 'authorise') {
				if (event.data.state === session) {
					authorise(event.data);
				}
				if (authPopupWindow) authPopupWindow.close();
				setAuthPopupWindow(null);
			}
		}

		if (session && authDetails) {
			authorise(authDetails);
			setAuthDetails(null);
		}
	
		window.addEventListener('message', authoriseFromPopup);
		return () => window.removeEventListener('message', authoriseFromPopup);
	}, [session, authPopupWindow, history, authDetails, setAuthDetails]);

	useEffect(() => {
		login();
	}, [login]);

	useEffect(() => {
		socket.emit('authorise', session);
	}, [session]);

	useEffect(() => {
		if (!offline) socket.on('disconnect', () => {
			queryClient.invalidateQueries(['/ping']);
		});

		return () => socket.off('disconnect');
	}, [offline, queryClient]);

	useEffect(() => {
		if (offline) socket.on('connect', () => {
			setTimeout(refresh, 2000);
		});

		return () => socket.off('connect');
	}, [offline])

	function handleLogout() {
		delete axios.defaults.headers.common['Developer'];
		localStorage.removeItem('token');
		setSession(null);
		setUsername(null);
		setUserAvatar(null);
		setDeveloper(false);
		setDeveloperMode(false);

		axios.get('/logout', { token: localStorage.getItem('token') }).then(response => {
			setSession(response.data.session);
			axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.session}`;
			message.info('Logged out');
		}).catch(error => {
			axios.defaults.headers.common['Authorization'] = null;
		});
	}

	function openAuthPopupWindow(url) {
		if (authPopupWindow) {
			authPopupWindow.close();
			setAuthPopupWindow(null);
		}
		setAuthPopupWindow(window.open(url, '_blank', 'width=500, height=800, menubar=no, toolbar=no'));
	}

	function handleLoginClick() {
		openAuthPopupWindow(`https://discord.com/api/oauth2/authorize?client_id=${ process.env.REACT_APP_DISCORD_ID }&redirect_uri=${ encodeURIComponent( process.env.REACT_APP_WEBPANEL_URL + "/authorise") }&response_type=code&state=${session || ''}&scope=identify%20guilds&prompt=consent`);
	}

	function toggleDeveloperMode(checked) {
		if (checked) axios.defaults.headers.common['Developer'] = true;
		else delete axios.defaults.headers.common['Developer'];
		setDeveloperMode(checked);

		queryClient.invalidateQueries();
	}

	function Unauthed({ joinGuild, hideAbout }) {
		const breakpoints = Grid.useBreakpoint();

		let content = <Alert message="Login" description={ joinGuild ? "Please login to view this guild" : "Please login to view your guilds and get started..." } icon={<LoginOutlined />} showIcon={true} action={(!session || authLoading) ? <Spin style={{ margin: '0.5rem' }} /> : <Button size="large" type="primary" onClick={handleLoginClick} style={{ margin: breakpoints.sm ? undefined : '1rem 8px 0 0' }} block={!breakpoints.sm}>Login with Discord</Button>} style={{ marginBottom: '1rem' }} className={breakpoints.sm ? '' : 'vertical-alert'} />;

		if (offline) content = <Alert message="Offline" description="I couldn't connect to Mizar's servers. Please try again later" type="error" showIcon={true} />

		if (joinGuild || hideAbout) return (
			<div style={{ padding: '2rem', maxWidth: '40rem', margin: '0 auto' }}>
				{ content }
			</div>
		);

		return <About welcome={true} middleContent={content} />;
	}

	function Header({ mode }) {
		return (
			<>
				<div className={styles.brand}>
					<Link to="/"><img src="/logo-256.png" alt="Mizar's logo" /><span>Mizar</span></Link>
				</div>

				{ navbar ||
					<Menu theme="dark" mode={mode} style={{ flexGrow: 1 }} selectedKeys={mainMenu.filter(item => pathname.startsWith(`/${item}`))}>
						<Menu.Item key="about"><Link to="/about">About</Link></Menu.Item>
						<Menu.Item key="invite"><Link to="/invite">Invite</Link></Menu.Item>
						<Menu.Item key="changelog"><Link to="/changelog">Changelog</Link></Menu.Item>
						<Menu.Item key="commands"><Link to="/commands">Commands</Link></Menu.Item>
					</Menu> }

				<div className={styles.account}>
					{ offline ? 
						<Tooltip title="Could not connect to Mizar's servers. Please try again later!">
							<WarningOutlined style={{ marginRight: '0.5rem', color: 'red' }} />
							<span style={{ marginRight: '1rem' }}>Offline</span>
						</Tooltip>
					: ( username ? 
						<>
							{ developer && <RadioSwitch onChange={toggleDeveloperMode} checked={developerMode} style={{ marginRight: '1rem' }} /> }

							<div>
								{userAvatar && <Avatar alt="Profile picture" src={userAvatar} style={{ marginRight: '1rem' }} />}
								<span style={{ marginRight: '1rem' }}>{username}</span>
							</div>

							<Button type="primary" onClick={handleLogout} block={mode === 'vertical'}>Logout</Button>
						</>
						: ( !session || authLoading ? <Spin size="small" style={{ fontSize: '0.3em' }} /> : <Button type="primary" onClick={handleLoginClick}>Login</Button> )
					)}
				</div>
			</>
		);
	}

	return (
		<>
			<Helmet>
				<title>Mizar | General Discord Bot</title>
				<link rel="shortcut icon apple-touch-icon apple-touch-icon-precomposed" href="/logo-256.png" type="image/png" sizes="256x256"></link>
			</Helmet>

			<Layout>
				{ screens.lg && <Layout.Header style={{ display: 'flex', position: 'fixed', zIndex: 3, width: '100%' }}>
					<Header mode='horizontal' />
				</Layout.Header> }

				<Layout.Content style={{ margin: screens.lg ? '64px 0 0' : 0 }}>
					<Switch>
						<Route path="/support" render={() => { window.location.replace(process.env.REACT_APP_DISCORD_URL); return null; }} />
						<Route path="/discord" render={() => { window.location.replace(process.env.REACT_APP_DISCORD_URL); return null; }} />
						<Route path="/roadmap" render={() => { window.location.replace(process.env.REACT_APP_ROADMAP_URL); return null; }} />
						<Route path="/docs" render={() => { window.location.replace(process.env.REACT_APP_DOCS_URL); return null; }} />
						<Route path="/privacy" render={() => { window.location.replace(process.env.REACT_APP_DOCS_URL+'/privacy'); return null; }} />
						<Route path="/twitter" render={() => { window.location.replace(process.env.REACT_APP_TWITTER_URL); return null; }} />
						<Route path="/patreon" render={() => { window.location.replace(process.env.REACT_APP_PATREON_URL); return null; }} />
						<Route path="/about" component={About} />
						<Route path="/invite">
							<InviteMizar authed={username} handleLoginClick={handleLoginClick} state={session} openAuthPopupWindow={openAuthPopupWindow} renderUnauthed={() => <Unauthed hideAbout={true} /> } />
						</Route>
						<Route path="/donate" component={Donate} />
						<Route path="/vote" component={Vote} />

						<Route path="/changelog">
							<Changelog />
						</Route>
						<Route path="/commands">
							<Commands />
						</Route>

						<Route path="/i/:guildId">
							<Invite authed={username} renderUnauthed={() => <Unauthed joinGuild={true} /> } />
						</Route>

						<Route path="/g/:guildId">
							{ username ? <Guild setNavbar={setNavbar} developerMode={developerMode} /> : <Unauthed /> }
						</Route>

						<Route exact path="/">
							{ username ? <GuildSelector developerMode={developerMode} state={session} openAuthPopupWindow={openAuthPopupWindow} /> : <Unauthed /> }
						</Route>

						<Redirect to="/" />
					</Switch>

					<Layout.Footer style={{ paddingTop: '0.5rem', paddingBottom: '0.3rem' }}>
						<Footer />
					</Layout.Footer>
				</Layout.Content>

				{ screens.lg || <Layout.Sider collapsedWidth={0} collapsible reverseArrow={true} style={{ padding: '1rem 0', position: 'fixed', height: '100vh', right: 0 }} className={styles.verticalMenu} defaultCollapsed={true}>
					<Header mode='vertical' />
				</Layout.Sider> }
			</Layout>
		</>
	);
}

export default App;