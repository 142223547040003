import React, { useState, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Typography, Table, Avatar, Modal, Skeleton, Tag, Input, message, Descriptions, Row, Col, Alert, Button } from 'antd';
import { CalendarOutlined, FieldTimeOutlined, RobotOutlined, StarOutlined, MessageOutlined, HourglassOutlined, ExperimentOutlined, UserOutlined, TagsOutlined, DeleteOutlined } from '@ant-design/icons';
import ReactGA from 'react-ga';
import axios from '../../../axios';

function SelectedGuildMember({ id, guildId, tag, avatar, onClose }) {
	const [ loading, setLoading ] = useState(false);
	const memberInfo = useQuery([`/administration/members/${id}`, guildId], { onError: () => {
		message.error(`Couldn't find ${tag}.`);
		onClose()
	}});
	const queryClient = useQueryClient();

	function deleteUser() {
		if (process.env.REACT_APP_ANALYTICS_KEY) ReactGA.event({
			category: 'Admin Panel',
			action: 'Delete User',
			transport: 'beacon'
		});

		setLoading(true);
		axios.delete(`/administration/members/${id}`).then(data => {
			queryClient.invalidateQueries([`/administration/members?page=${1}&q=`, guildId]);
			message.info('User deleted');
			onClose();		
			setLoading(false);
		}).catch(() => {
			message.error('An error occured');
			setLoading(false);	
		});
	}

	function onDeleteClicked() {
		Modal.confirm({
			title: 'Delete User',
			content: `Are you sure you want to delete ${tag}? This action cannot be undone.`,
			okText: 'Delete',
			okType: 'danger',
			icon: <DeleteOutlined />,
			onOk: deleteUser
		});
	}

	if (memberInfo.isLoading || loading || !memberInfo.data) return <Skeleton />;

	return (
		<div>
			{ memberInfo.data.deleteAt && <Alert type="warning" message="Not in Guild" description={<span>This user is no longer in your guild and will be deleted on {(new Date(memberInfo.data.deleteAt)).toUTCString()}</span>} style={{ marginBottom: '1rem' }} /> }

			<Row>
				<Col span={12}>
					<Avatar size={100} shape="square" src={memberInfo.data.avatarUrl || avatar} alt="User avatar" />

					<Typography.Title level={2}>{memberInfo.data.displayName || memberInfo.data.tag}</Typography.Title>

					<div>
						{ memberInfo.data.roles && memberInfo.data.roles.map(role => <Tag style={{ borderColor: role.color, margin: '0.2rem' }} key={role.id}>{role.name}</Tag> )}
					</div>
				</Col>
				<Col span={12}>
					<Descriptions layout="horizontal" size="small" bordered={false} column={1}>
						<Descriptions.Item label={<strong><CalendarOutlined /> Joined</strong>}>{memberInfo.data.joinedAt ? (new Date(memberInfo.data.joinedAt)).toUTCString() : 'Not in guild'}</Descriptions.Item>
						{ memberInfo.data.leftAt && <Descriptions.Item label={<strong><CalendarOutlined /> Left</strong>}>{ (new Date(memberInfo.data.leftAt)).toUTCString()}</Descriptions.Item> }
						{ memberInfo.data.timezone && <Descriptions.Item label={<strong><FieldTimeOutlined /> Timezone</strong>}>{ memberInfo.data.timezone}</Descriptions.Item> }
						{ memberInfo.data.voice && <Descriptions.Item label={<strong><RobotOutlined /> Voice</strong>}>{memberInfo.data.voice}</Descriptions.Item> }
					</Descriptions>

					<Button type="danger" onClick={onDeleteClicked}>Delete User</Button>
				</Col>
				{( memberInfo.data.experience || memberInfo.data.messagesSent || memberInfo.data.voiceTime ) && <Col span={12}>
					<Descriptions layout="horizontal" size="small" bordered={false} column={1} title="Levelling" style={{ marginTop: '2rem' }}>
						<Descriptions.Item label={<strong><ExperimentOutlined /> Experience</strong>}>{memberInfo.data.experience || 0}</Descriptions.Item>
						<Descriptions.Item label={<strong><StarOutlined /> Level</strong>}>{memberInfo.data.level || 0}</Descriptions.Item>
						<Descriptions.Item label={<strong><MessageOutlined /> Messages Sent</strong>}>{memberInfo.data.messagesSent || 0}</Descriptions.Item>
						<Descriptions.Item label={<strong><HourglassOutlined /> VC Time</strong>}>{memberInfo.data.voiceTime || '0s'}</Descriptions.Item>
					</Descriptions>
				</Col> }
				{( memberInfo.data.leftAt ) && <Col span={12}>
					<Descriptions layout="horizontal" size="small" bordered={false} column={1} title="Persisted Data" style={{ marginTop: '2rem' }}>
						<Descriptions.Item label={<strong><UserOutlined /> Nickname</strong>}>{memberInfo.data.nickname || 'None'}</Descriptions.Item>
						<Descriptions.Item label={<strong><TagsOutlined /> Roles</strong>}>{memberInfo.data.oldRoles ? memberInfo.data.oldRoles.join(', ') : 'None'}</Descriptions.Item>
					</Descriptions>
				</Col> }
			</Row>
		</div>
	);
}

const Members = React.memo(function Members({ guildId }) {
	const [ pagination, setPagination ] = useState({ current: 1, pageSize: 100, showSizeChanger: false });
	const { current: currentPage } = pagination;
	const [ search, setSearch ] = useState('');

	const membersInfo = useQuery([`/administration/members?page=${currentPage}&q=${search}`, guildId], { onError: () => message.error("Couldn't fetch members.") });
	const { data: membersData } = membersInfo;

	const [ selectedMember, setSelectedMember ] = useState(null);

	useEffect(() => {
		if (!membersData) return;

		setPagination(pagination => ({ ...pagination, total: membersData.count }));
	}, [membersData]);

	function onTableChange(pagination) {
		setPagination(pagination);
	}

	return (
		<div style={{ padding: '1rem 2rem' }}>
			<Typography.Title level={2}>Tracked Members</Typography.Title>

			<p>All members that are tracked on my database are shown here. They will only be tracked if they have interacted with me or if they have experience.</p>

			<Input.Search placeholder="Search"  onSearch={query => setSearch(query)} style={{ marginBottom: '1rem' }} allowClear enterButton />

			<Table loading={membersInfo.isLoading} dataSource={membersData ? membersData.members : []} size="small" pagination={pagination} onChange={onTableChange} locale={{ emptyText: "No members found" }} onRow={member => ({ onClick: () => setSelectedMember(member) })} rowKey="id">
				<Table.Column title="Tag" dataIndex="tag" key="tag" render={(_, user) => (
					<>
						<Avatar src={user.avatar} alt={`Avatar of ${user.tag}`} style={{ marginRight: '0.5rem' }} />
						{user.tag}
					</>
				)} />
				<Table.Column title="Level" dataIndex="level" key="level" />
				<Table.Column title="Experience" dataIndex="exp" key="experience" />
				<Table.Column title="Left At" dataIndex="leftAt" key="leftAt" render={date => date ? (new Date(date)).toUTCString() : ''} responsive={["sm"]} />
			</Table>

			<Modal visible={selectedMember} onCancel={() => setSelectedMember(null)} title={selectedMember && selectedMember.tag} destroyOnClose={true} width={700} okButtonProps={{ style: { display: 'none' }}} cancelText="Close">
				{ selectedMember && <SelectedGuildMember guildId={guildId} id={selectedMember.id} tag={selectedMember.tag} avatar={selectedMember.avatar} onClose={() => setSelectedMember(null)} /> }
			</Modal>
		</div>
	);
});

export default Members;