import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import axios from './axios';
import ReactGA from 'react-ga';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/apm';

//import * as serviceWorker from './serviceWorker';

import { Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './App.less';
import App from './components/App';
import Authorise from './components/Authorise';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: (failureCount, error) => {
				if (error.response) {
					if (error.response.status === 404 || error.response.status === 403 || error.response.status === 422) return false;
				}

				if (failureCount > 2) return false;
				return 3;
			},
			select: data => data.data,
			queryFn: ({ queryKey }) => {
				let url = queryKey[0];
				return axios.get(url);
			},
			onError: error => {
				if (error.code === 'ECONNABORTED') return;
				if (error.response && error.response.status === 403) return;
				
				console.error(error);
				message.error("Couldn't fetch data.");
			},
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			refetchInterval: 600000 //10m
		}
	}
});
Spin.setDefaultIndicator(<LoadingOutlined style={{ fontSize: '3em' }} />);

if (process.env.REACT_APP_SENTRY_DSN) {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		release: 'mizar-client-' + process.env.REACT_APP_RELEASE_NAME,
		integrations: [
			new Integrations.Tracing(),
		],
		tracesSampleRate: 1.0,
	});
}

// Google Analytics
if (process.env.REACT_APP_ANALYTICS_KEY) {
	const query = new URLSearchParams(window.location.search);
	
	ReactGA.initialize(process.env.REACT_APP_ANALYTICS_KEY);

	if (!query.get('utm_source') && query.get('state')?.startsWith('referrer')) {
		// Probably doesn't work
		ReactGA.ga('push', ['_setCampSourceKey', 'state']);
	}
}

console.warn('Do not paste anything in here - doing so could give an attacker full access to the servers Mizar manages')

function Main() {
	const [ authDetails, setAuthDetails ] = useState(null);

	return (
		<Router>
			<Switch>
				<Route exact path="/authorise">
					<Authorise setAuthDetails={setAuthDetails} />
				</Route>
				<Route>
					<App authDetails={authDetails} setAuthDetails={setAuthDetails} />
				</Route>
			</Switch>
		</Router>
	);
}

ReactDOM.render(<QueryClientProvider client={queryClient}>
	<Main />
</QueryClientProvider>, document.getElementById('root'));

/*if (process.env.NODE_ENV === 'production') serviceWorker.register({
	onSuccess: () => window.dispatchEvent(new Event('serviceWorkerSuccess')),
	onUpdate: (registration) => window.dispatchEvent(new Event('serviceWorkerUpdate', { registration: registration }))
});
else serviceWorker.unregister();*/