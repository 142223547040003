import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Spin, Result } from 'antd';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export default function Authorise({ setAuthDetails }) {
	const history = useHistory();
	const [ success, setSuccess ] = useState(false);

	const query = useQuery();
	const guildId = query.get('guild_id');
	const code = query.get('code');
	const state = query.get('state');

	useEffect(() => {
		if (window.opener && code && state) {
			window.opener.postMessage({
				guildId: guildId,
				code: code,
				state: state,
				type: 'authorise'
			}, process.env.REACT_APP_WEBPANEL_URL);

			setSuccess(true);
		}
		else {
			setAuthDetails({
				code: code,
				state: state || 'new',
				guildId: guildId
			});
			history.push('/');
		}
	}, [guildId, code, state, history, setAuthDetails]);

	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
			{ success ? 
				<Result status='success' title={guildId ? 'Guild Added' : 'Logged In'} subTitle='Please return to Mizar' />
			: 
				<Spin style={{ marginBottom: '2rem' }} />
			}
		</div>
	);
}