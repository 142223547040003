import React, { useEffect, useState, useMemo } from 'react';
import { Switch, Route, Link, Redirect, useRouteMatch, useLocation } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { Layout, Menu, Modal, Button, Alert, Collapse, Grid } from 'antd';
import { QuestionOutlined } from '@ant-design/icons';
import websocket from '../../websocket';
import { Loading } from '../../Components';

import GeneralSettings from './GeneralSettings';
import CustomCommands from './CustomCommands';
import DeveloperSettings from './DeveloperSettings';
import Module from './Module';
import Members from './Members';

function OnboardPopup(props) {
	const { close, guildName } = props;
	const [ open, setOpen ] = useState(true);

	return (
		<>
			{ !open && <Button type="primary" shape="circle" icon={<QuestionOutlined />} size="large" style={{ position: 'fixed', left: 20, bottom: 20, zIndex: 9999 }} onClick={() => setOpen(true)} /> }

			<Modal visible={open} onCancel={() => setOpen(false)} cancelText="Close" onOk={close} okText="Finish" title={`Welcome to Mizar, ${guildName}!`} width={1000}>
				<Alert description={<span>To get the best Mizar experience you'll need to configure it to your custom needs.<br /><br />This popup will guide you through the first basic steps - but if you know what you're doing, feel free to skip the process using the finish button. If you need me again, go to /admin/onboard (a link should have been sent in your DMs)</span>} type="info" />

				<Collapse accordion style={{ marginTop: '1rem' }}>
					<Collapse.Panel header="1. The Admin Panel" key="1">
						<p>Mizar can be fully configured using the admin panel. You can reset the entire configuration on the General Settings page, or individual modules on their respective tab</p>
					</Collapse.Panel>
					<Collapse.Panel header="2. General Settings" key="2">
						<p>Go through the whole General Settings page. You can set your prefix and timezone here, as well as where to send bot admin messages and whether to send the changelog when Mizar is updated (recommended)</p>
					</Collapse.Panel>
					<Collapse.Panel header="3. NSFW Commands" key="3">
						<p>By default, NSFW classed commands will only be accessible in NSFW channels. Depending on your use case, you may want to disable this as it may be too restrictive. See the Search/Wikipedia, Search/Urban and Random/Inspire commands to configure restrictions</p>
					</Collapse.Panel>
					<Collapse.Panel header="4. Joining and Leaving Module" key="4">
						<p>The joining and leaving module can send custom messages and assign roles when a user joins or leaves your guild. You can also enable user persistence (keeping a user's roles and nickname if they leave) and setup the invite, captcha and raid protection system</p>
					</Collapse.Panel>
					<Collapse.Panel header="5. Levelling Module" key="5">
						<p>You may wish to enable the levelling module, which will keep track of how many messages each person has sent and how long they have spent in voice. Although there is a levelling and rank system, this can be separately disabled if wanted. Note that this will only track messages/time when it is enabled!</p>
					</Collapse.Panel>
					<Collapse.Panel header="6. Moderation Module" key="6">
						<p>Mizar can provide moderation tools such as mutes, tempbans, modmail and message purging. It can also keep an audit log of all actions taken</p>
					</Collapse.Panel>
					<Collapse.Panel header="7. Extra Mizar Users" key="7">
						<p>Mizar offers several extra bot users which enables it to play music or TTS in multiple channels at once. This is an optional feature, and can be configured on the General Settings page</p>
					</Collapse.Panel>
					<Collapse.Panel header="8. Removing Mizar" key="8">
						<p>If you want to remove Mizar (😞), ensure that you unmute all users and then just kick Mizar. Your settings will be kept for up to a week until they are permanently deleted, however be aware that they may be deleted at any point before then. Remember that everything that Mizar is running (including tempbans and reaction rules) will be stopped</p>
					</Collapse.Panel>
					<Collapse.Panel header="Documentation" key="9">
						<p>My <a href={`${process.env.REACT_APP_DOCS_URL}?utm_source=onboarding&utm_medium=webpanel`} target="_blank" rel="noopener noreferrer">documentation</a> can be found at <a href={`${process.env.REACT_APP_DOCS_URL}?utm_source=onboarding&utm_medium=webpanel`} target="_blank" rel="noopener noreferrer">{process.env.REACT_APP_DOCS_URL}</a></p>
					</Collapse.Panel>
					<Collapse.Panel header="Support" key="10">
						<p>Support is available on the <a href="/support" target="_blank">support Discord server.</a> Feel free to join to test Mizar, ask questions and offer suggestions & improvements!</p>
					</Collapse.Panel>
				</Collapse>
			</Modal>
		</>
	);
}

function Admin({ guildName, guildId, developerMode }) {
	const screens = Grid.useBreakpoint();
	const { url:baseUrl } = useRouteMatch();
	const { pathname } = useLocation();

	const adminInfo = useQuery(['/administration/list', guildId]);
	const restrictionInfo = useQuery(['/administration/restrictions', guildId]);
	const modules = (adminInfo.isSuccess && adminInfo.data.modules) || [];
	const guildContext = useMemo(() => {
		if (adminInfo.isSuccess && adminInfo.data) {
			return {
				id: guildId,
				channels: adminInfo.data.channels,
				roles: adminInfo.data.roles,
				mainPrefix: adminInfo.data.mainPrefix,
				restrictions: restrictionInfo.data
			}
		}
		return {
			id: guildId
		};
	}, [adminInfo, restrictionInfo, guildId]);

	const [ onboard, setOnboard ] = useState(false);
	const [ collapsed, setCollapsed ] = useState(true);
	const queryClient = useQueryClient();

	function Onboard() {
		useEffect(() => {
			setOnboard(true);
		}, []);

		return (
			<Redirect to={baseUrl} />
		)
	}


	useEffect(() => {
		websocket.on('guild.administration.list.update', data => {
			queryClient.setQueryData(['/administration/list', guildId], { data: data });
		});
		websocket.emit('command', 'administration.subscribe', guildId);

		return (() => {
			websocket.emit('command', 'administration.unsubscribe', guildId);
			websocket.off('guild.options.menu.update');
			websocket.off('guild.options.menu.update');
		});
	}, [guildId, queryClient]);

	if (adminInfo.isLoading) return <Loading />;

	return (
		<Layout>
			<Layout.Sider style={{ position: 'fixed', left: 0, height: '100vh', paddingTop: screens.lg ? 74 : 5, marginTop: screens.lg ? -64 : 0, zIndex: 2 }} collapsible={!screens.lg} collapsedWidth={0} collapsed={!screens.lg && collapsed} onCollapse={collapsed => setCollapsed(collapsed)}>
				<div style={{ maxHeight: screens.lg ? '90vh' : '100vh', overflow: 'auto', minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
					<Menu theme="dark" selectedKeys={[pathname.split('/')[4] || 'general']} style={{ marginBottom: 'auto' }}>
						<Menu.Item key="general"><Link to={baseUrl}>General Settings</Link></Menu.Item>
						<Menu.Item key="commands"><Link to={`${baseUrl}/commands`}>Custom Commands</Link></Menu.Item>
						<Menu.Item key="members"><Link to={`${baseUrl}/members`}>Members</Link></Menu.Item>
						{ developerMode && <Menu.Item key="developer"><Link to={`${baseUrl}/developer`}>Developer Settings</Link></Menu.Item> }

						<Menu.ItemGroup title="Modules">
							{ modules.map(module => {
								let style = {};
								if (module.colour) style.color = module.colour;
								if (!module.enabled) style.textDecoration = 'line-through';

								return (<Menu.Item key={module.id}><Link to={`${baseUrl}/${module.id}`}><span style={style}>{module.name}</span></Link></Menu.Item>);
							})}
						</Menu.ItemGroup>
					</Menu>
				</div>
				
			</Layout.Sider>
			<Layout.Content style={{ maxWidth: '100rem', margin: '0 auto' }}>
				<div style={{ marginLeft: screens.lg ? 200 : 0 }}>
					<Switch>
						<Route exact path={`${baseUrl}`}>
							<GeneralSettings guildName={guildName} guildId={guildId} guildContext={guildContext} setOnboard={setOnboard} />
						</Route>
						<Route path={`${baseUrl}/onboard`}>
							<Onboard />
						</Route>
						<Route path={`${baseUrl}/members`}>
							<Members guildId={guildId} />
						</Route>
						<Route path={`${baseUrl}/commands`}>
							<CustomCommands guildContext={guildContext} />
						</Route>
						{ developerMode && 
							<Route path={`${baseUrl}/developer`}>
								<DeveloperSettings />
							</Route> 
						}
						{ modules.map(module => (
							<Route path={`${baseUrl}/${module.id}`} key={module.id}>
								<Module id={module.id} guildContext={guildContext} name={module.name} guildId={guildId} />
							</Route>
						))}
						<Route>
							<Redirect to={baseUrl} />
						</Route>
					</Switch>
				</div>
			</Layout.Content>

			{ onboard && <OnboardPopup guildName={guildName} close={() => setOnboard(false)} /> }
		</Layout>
	);
}

export default Admin;