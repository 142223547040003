import React, { useState, Fragment, useMemo } from 'react';
import { Select, Divider, Card, List, Button, Input, message } from 'antd';
import { DeleteOutlined, PlusOutlined, StopOutlined, UserOutlined, AuditOutlined, TagOutlined, NotificationOutlined } from '@ant-design/icons';
import { RoleField, ChannelField, UserField } from './FormElements';
import Docs from '../../Docs';

const permissionOptions = [
	{ key: 'administrator', text: 'Administrator' },
	{ key: 'guild.manage', text: 'Manage Guild' },
	{ key: 'events.manage', text: 'Manage Events' },
	{ key: 'channels.manage', text: 'Manage Channels' },
	{ key: 'webhooks.manage', text: 'Manage Webhooks' },
	{ key: 'roles.manage', text: 'Manage Roles' },
	{ key: 'emojis.manage', text: 'Manage Emojis' },
	{ key: 'mention.everyone', text: 'Mention Everyone' },
	{ key: 'messages.send', text: 'Send Messages' },
	{ key: 'messages.manage', text: 'Manage Messages' },
	{ key: 'reactions.add', text: 'Add Reactions' },
	{ key: 'voice.speak', text: 'Speak' },
	{ key: 'voice.mute', text: 'Mute Members' },
	{ key: 'voice.deafen',  text: 'Deafen Members' },
	{ key: 'voice.move', text: 'Move Members' },
	{ key: 'nicknames.change', text: 'Change Nickname' },
	{ key: 'nicknames.manage', text: 'Manage Nicknames' },
	{ key: 'members.moderate', text: 'Moderate Members' },
	{ key: 'members.kick', text: 'Kick Members' },
	{ key: 'members.ban', text: 'Ban Members' }
];

export default React.memo(function Restrictions({ id, value: restrictions, onChange: setRestrictions, setRestrictionDefinition, guildContext, restrictionChanges, disabled }) {
	const restrictionsList = useMemo(() => {
		if (!restrictions) return ['__add__'];
		
		let list = restrictions.slice();
		list.push('__add__');
		return list;
	}, [restrictions]);

	function addRestriction(restrictionName) {
		if (disabled) return;
		if (!restrictions) {
			setRestrictions([restrictionName]);
		}
		else {
			var _restrictions = restrictions.slice();
			_restrictions.push(restrictionName);
			setRestrictions(_restrictions);
		}
	}
	function removeRestriction(restrictionName) {
		if (disabled) return;
		var _restrictions = restrictions.slice();
		_restrictions = _restrictions.filter(restriction => restriction !== restrictionName);
		setRestrictions(_restrictions);
	}

	return (
		<>
			<Docs path="/getting-started/restrictions" float="right" type="button" />
			<p style={{ fontSize: '0.85em' }}>See the <a href={`${process.env.REACT_APP_DOCS_URL}/getting-started/restrictions?utm_source=docs-link&utm_medium=webpanel`} target="_blank" rel="noopener noreferrer">Support Documentation</a> for more information. Only one of these restrictions need to be met for the restriction to pass.</p>
			
			<List grid={{ gutter: 16, xs: 1, sm: 2, md: 3 }} dataSource={restrictionsList} renderItem={restriction => restriction === '__add__' ? <AddRestriction guildContext={guildContext} disabled={disabled} restrictions={restrictions} handleAddRestriction={addRestriction} restrictionChanges={restrictionChanges} setRestrictionDefinition={setRestrictionDefinition} /> : <Restriction key={restriction} name={restriction} disabled={disabled} handleRemove={() => removeRestriction(restriction)} setRestriction={setRestrictionDefinition} restrictionChange={restrictionChanges[restriction]} guildContext={guildContext} />} id={id} />
		</>
		
	)
});

const AddRestriction = React.memo(function AddRestriction({ restrictions, disabled, handleAddRestriction, restrictionChanges, setRestrictionDefinition, guildContext: { restrictions: guildRestrictions } }) {
	const [ newExistingRestrictionName, setNewExistingRestrictionName ] = useState('__new__');
	const [ newRestrictionName, setNewRestrictionName ] = useState('');
	const newRestrictionOptions = useMemo(() => {
		let options = Object.keys(guildRestrictions);
		if (restrictionChanges) Object.keys(restrictionChanges).forEach(name => !options.includes(name) && options.push(name));
		if (restrictions) options = options.filter(restriction => !restrictions.includes(restriction));
		options.unshift('__new__');
		
		return options;
	}, [guildRestrictions, restrictionChanges, restrictions]);

	function add() {
		let name = newExistingRestrictionName;
		if (name === '__new__') name = newRestrictionName;

		if (!name) return message.error('A name is required');
		if (restrictions && restrictions.includes(name)) return message.error('Restriction is already set');

		handleAddRestriction(name.toLowerCase().replace(/[^0-9a-z]/g, ''));
		setNewExistingRestrictionName('_new');
		setNewRestrictionName('');

		if (newExistingRestrictionName === '_new' && !Object.keys(guildRestrictions).includes(name)) setRestrictionDefinition(name, [[{
			'type': 'permission',
			'permission': 'administrator'
		}]]);
	}

	return (
		<Card title="Add New" bordered={true} style={{ margin: '0.3rem', backgroundColor: 'var(--color-grey-25)', maxWidth: '20rem', minWidth: '15rem' }} disabled={disabled}>
			<Select showSearch style={{ width: '100%' }} placeholder='Existing Restriction' optionFilterProp='value' filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0} value={newExistingRestrictionName} onChange={value => setNewExistingRestrictionName(value)} disabled={disabled}>
				{ newRestrictionOptions.map(restriction => <Select.Option value={restriction} key={restriction}>{restriction === '__new__' ? 'Create New (below)' : restriction}</Select.Option> )}
			</Select>

			{ newExistingRestrictionName === '__new__' &&
				<Input placeholder="New Restriction Name..." value={newRestrictionName} onChange={({ target }) => setNewRestrictionName(target.value)} onKeyPress={event => { if (event.key === 'Enter') add() }} style={{ marginTop: '0.5rem' }} min={1} max={30} disabled={disabled} />
			}

			<Button style={{ marginTop: '0.5rem' }} onClick={add} disabled={disabled}>{newExistingRestrictionName === '__new__' ? 'Create' : 'Add'}</Button>
		</Card>
	);
});

const Restriction = React.memo(function Restriction({ name, disabled, handleRemove, setRestriction, restrictionChange, guildContext: { roles, channels, restrictions: guildRestrictions }}) {
	const restriction = useMemo(() => {
		if (restrictionChange) return restrictionChange;
		return guildRestrictions[name];
	}, [name, guildRestrictions, restrictionChange])
	const canRemovePath = restriction && restriction.length > 1;

	function addPath() {
		if (disabled) return;
		var newState = restriction ? restriction.slice() : [];
		newState.push([{
			'type': 'permission',
			'permission': 'administrator'
		}]);
		setRestriction(name, newState);
	}
	function setPath(i, path) {
		if (disabled) return;
		if (path.length === 0) {
			var newState = restriction.slice();
			newState.splice(i, 1);
			setRestriction(name, newState);
		}
		else {
			var _newState = restriction.slice();
			_newState[i] = path;
			setRestriction(name, _newState);
		}
	}

	return (
		<Card title={name} bordered={true} style={{ margin: '0.3rem', backgroundColor: 'var(--color-grey-25)', maxWidth: '20rem', minWidth: '15rem' }} bodyStyle={{ padding: '0.5rem' }} extra={<DeleteOutlined onClick={handleRemove} style={{ color: disabled ? '#cccccc44' : '#cccccc', cursor: disabled ? 'not-allowed' : undefined }} />}>
			{
				restriction && restriction.map((restrictionPath, i) => (
					<Fragment key={i}>
						{ i > 0 && <Divider plain>Or</Divider> }
						<RestrictionPath path={restrictionPath} setPath={(path) => setPath(i, path)} canRemove={canRemovePath} roles={roles} channels={channels} disabled={disabled} />
					</Fragment>
				))
			}

			<div style={{ textAlign: 'center '}}>
				<PlusOutlined onClick={addPath} style={{ color: disabled ? '#cccccc44' : '#cccccc', fontSize: '1.3em', cursor: disabled ? 'not-allowed' : undefined }} />
			</div>
		</Card>
	);
});

function RestrictionPath({ path, setPath, canRemove, roles, channels, disabled }) {
	let canRemoveCondition = path.length > 1 || canRemove;

	function addCondition() {
		if (disabled) return;
		var newState = path.slice();
		newState.push({
			'type': 'permission',
			'permission': 'administrator'
		});
		setPath(newState);
	}
	function setCondition(i, condition) {
		if (disabled) return;
		var newState = path.slice();
		newState[i] = condition;
		setPath(newState);
	}
	function removeCondition(conditionId) {
		if (disabled) return;
		if (!canRemoveCondition) return;
		var newState = path.slice();
		newState.splice(conditionId, 1);
		setPath(newState);
	}

	return (
		<div style={{ textAlign: 'center', padding: '0.3rem 0.3rem 0 0.3rem', border: '0.05rem dashed rgb(240, 240, 240, 0.1)', borderRadius: '0.2rem', margin: 0 }}>
			{
				path && path.map((restrictionCondition, i) => (
					<Fragment key={i}>
						{ i > 0 && <Divider plain>And</Divider> }
						<RestrictionCondition condition={restrictionCondition} removeCondition={() => removeCondition(i)} setCondition={(condition) => setCondition(i, condition)} canRemove={canRemoveCondition} roles={roles} channels={channels} disabled={disabled} />
					</Fragment>
				))
			}
			<PlusOutlined onClick={addCondition} style={{ color: disabled ? '#cccccc44' : '#cccccc', fontSize: '1.1em', cursor: disabled ? 'not-allowed' : undefined }} />
		</div>
	);
}

function RestrictionCondition({ setCondition, removeCondition, canRemove, roles, channels, condition, disabled }) {
	function setType(type) {
		let condition = { type: type };
		switch(type) {
			case 'permission':
				condition.permission = 'administrator'
				break;
			case 'role':
				condition.roleId = roles.length > 0 ? roles[0].id : '';
				break;
			case 'channel':
				condition.channelId = channels.length > 0 ? channels[0].id : '';
				break;
			case 'user':
				condition.userId = '';
				break;
			default:
				break;
		}
		setCondition(condition);
	}

	function handleInvert() {
		var newState = Object.assign({}, condition);
		newState.inverted = !condition.inverted;
		setCondition(newState);
	}

	function setPermission(permission) {
		var newState = Object.assign({}, condition);
		newState.permission = permission;
		setCondition(newState);
	}
	function setRole(roleId) {
		var newState = Object.assign({}, condition);
		newState.roleId = roleId;
		setCondition(newState);
	}
	function setChannel(channelId) {
		var newState = Object.assign({}, condition);
		newState.channelId = channelId;
		setCondition(newState);
	}
	function setUser(userId) {
		var newState = Object.assign({}, condition);
		newState.userId = userId;
		setCondition(newState);
	}

	let conditionType = <RestrictionConditionType value="other" setType={setType} disabled={disabled} />
	let field = <p>Other Restriction</p>;

	switch(condition.type) {
		case 'permission':
			conditionType = <RestrictionConditionType value="permission" setType={setType} disabled={disabled} />;
			field = <Select value={condition.permission} onChange={value => setPermission(value)} style={{ textAlign: 'left' }} showSearch notFoundContent="No Permissions Found" filterOption={(search, permission) => {
				if (permission.name.indexOf(search.toLowerCase()) !== -1) return true;
				return false;
			}} disabled={disabled} dropdownMatchSelectWidth={200}>
				{ permissionOptions.map(option => <Select.Option key={option.key} value={option.key} name={option.text.toLowerCase()}>{option.text}</Select.Option>)}
			</Select>;
			break;
			
		case 'role':
			conditionType = <RestrictionConditionType value="role" setType={setType} disabled={disabled} />;
			field = <RoleField roles={roles} onChange={val => setRole(val)} value={condition.roleId} style={{ textAlign: 'left' }} disabled={disabled} />
			break;

		case 'channel':
			conditionType = <RestrictionConditionType value="channel" setType={setType} disabled={disabled} />;
			field = <ChannelField channels={channels} value={condition.channelId} onChange={val => setChannel(val)} style={{ textAlign: 'left' }} nsfwOption={true} disabled={disabled} />
			break;
			
		case 'user':
			conditionType = <RestrictionConditionType value="user" setType={setType} disabled={disabled} />;
			field = <UserField value={condition.userId} onChange={val => setUser(val)} style={{ textAlign: 'left' }} disabled={disabled} />
			break;
		
		default:
			return (
				<p><strong>Other Restriction</strong></p>
			);
	}

	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			{ conditionType }
			{ field }
			<StopOutlined style={{ color: condition.inverted ? (disabled ? '#bd290f44' : '#bd290f') : (disabled ? '#adadad44' : '#adadad'), margin: '0 0.5rem', fontSize: '1.2em', cursor: disabled ? 'not-allowed' : undefined }} onClick={handleInvert} />
			{ canRemove && <DeleteOutlined style={{ fontSize: '1.1em', color: disabled ? '#cccccc44' : '#cccccc', marginRight: 0, cursor: disabled ? 'not-allowed' : undefined }} onClick={removeCondition} /> }
		</div>
	);
}

function RestrictionConditionType({ setType, value, disabled }) {
	const [ dropdownVisible, setDropdownVisible ] = useState(false);

	return (
		<Select value={value} onChange={setType} onDropdownVisibleChange={setDropdownVisible} dropdownMatchSelectWidth={false} style={{ width: '2.6rem', textAlign: 'left' }} showArrow={false} disabled={disabled}>
			<Select.Option value='permission'><AuditOutlined style={{ fontSize: '1.3em' }} />{dropdownVisible && <span style={{ marginLeft: '0.3rem'}}>Permission</span>}</Select.Option>
			<Select.Option value='role'><TagOutlined style={{ fontSize: '1.3em' }} />{dropdownVisible && <span style={{ marginLeft: '0.3rem'}}>Role</span>}</Select.Option>
			<Select.Option value='channel'><NotificationOutlined style={{ fontSize: '1.3em' }} />{dropdownVisible && <span style={{ marginLeft: '0.3rem'}}>Channel</span>}</Select.Option>
			<Select.Option value='user'><UserOutlined style={{ fontSize: '1.3em' }} />{dropdownVisible && <span style={{ marginLeft: '0.3rem'}}>User</span>}</Select.Option>
		</Select>
	);
}
