import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link } from "react-router-dom";
import { List, Card, Button, Typography, Input, Skeleton, message } from 'antd';
import { UserOutlined, SearchOutlined } from '@ant-design/icons';
import styles from './GuildSelector.module.css';
import ReactGA from 'react-ga';
import { Zoom } from 'react-awesome-reveal';

function GuildSelectorCard({ guild }) {
	return (
		<List.Item>
			<Zoom duration={100} triggerOnce>
				<Link to={`/g/${ guild.url }`}>
					<Card hoverable cover={<img alt="Server icon" src={guild.iconUrl} />} style={{ background: 'var(--color-grey-2)' }} className={ guild.owner ? styles.guildOwner : ( guild.admin && styles.guildAdmin )}>
						<Card.Meta title={guild.name} description={<>
							<UserOutlined style={{ marginRight: '0.3rem' }} />
							{guild.members} members
						</>} />
					</Card>
				</Link>
			</Zoom>
		</List.Item>
		
	)
}

function GuildSelectorToAddCard({ state, guild, openAuthPopupWindow }) {
	function addGuild() {
		if (process.env.REACT_APP_ANALYTICS_KEY) ReactGA.event({
			category: 'Guilds',
			action: 'Add',
			transport: 'beacon'
		});
		openAuthPopupWindow(`https://discord.com/api/oauth2/authorize?client_id=${ process.env.REACT_APP_DISCORD_ID }&redirect_uri=${ encodeURIComponent( process.env.REACT_APP_WEBPANEL_URL + "/authorise") }&state=${state}&response_type=code&scope=bot%20applications.commands&disable_guild_select=true&permissions=8&guild_id=${ guild.id }`,
		'_blank', 'width=500, height=800, menubar=no, toolbar=no');
	}
	function addCustomGuild() {
		if (process.env.REACT_APP_ANALYTICS_KEY) ReactGA.event({
			category: 'Guilds',
			action: 'Add (Custom Permissions)',
			transport: 'beacon'
		});
		openAuthPopupWindow(`https://discord.com/api/oauth2/authorize?client_id=${ process.env.REACT_APP_DISCORD_ID }&redirect_uri=${ encodeURIComponent( process.env.REACT_APP_WEBPANEL_URL + "/authorise") }&state=${state}&response_type=code&scope=bot%20applications.commands&disable_guild_select=true&permissions=296318660343&guild_id=${ guild.id }`,
		'_blank', 'width=500, height=800, menubar=no, toolbar=no');
	}

	return (
		<List.Item>
			<Zoom duration={100} triggerOnce>
				<Card hoverable cover={<img alt="Server icon" src={guild.iconUrl} />} style={{ background: 'var(--color-grey-2)' }} actions={[
					<div style={{ display: 'flex', justifyContent: 'stretch', flexWrap: 'wrap' }}>
						<Button onClick={addGuild} type="primary" block style={{ margin: '0.4rem 0.4rem 0', width: '40%', minWidth: '7rem', flexGrow: 1 }}>Invite</Button>
						<Button onClick={addCustomGuild}  type="secondary" block style={{ margin: '0.4rem 0.4rem 0', width: '40%', minWidth: '7rem', flexGrow: 1 }}>Custom Perms</Button>
					</div>
				]}>
					<Card.Meta title={guild.name} />
				</Card>
			</Zoom>
		</List.Item>
		
	)
}

function GuildSelector({ onlyShowGuildsToAdd, developerMode, state, openAuthPopupWindow }) {
	const guildInfo = useQuery(['/guilds', developerMode], { onError: () => message.error("Couldn't fetch your guilds."), refetchOnMount: true, refetchOnWindowFocus: true });
	const { data: guildData } = guildInfo;

	const [ guildsSearch, setGuildsSearch ] = useState('');
	
	const [ filteredGuilds, setFilteredGuilds ] = useState([]);
	const [ guildsToAddSearch, setGuildsToAddSearch ] = useState('');
	const [ filteredGuildsToAdd, setFilteredGuildsToAdd ] = useState([]);
	const [ developerGuildsSearch, setDeveloperGuildsSearch ] = useState('');
	const [ filteredDeveloperGuilds, setFilteredDeveloperGuilds ] = useState([]);

	/*
	useEffect(() => {
		websocket.on("guilds.updated", (guilds, guildsToAdd) => {
			
		});

		return (() => websocket.off("guilds.updated"));
	}, [guildPopupWindow]);

	useEffect(() => {
		websocket.on('guild.added', (guildId) => {
			guildPopupWindow.close();
			setGuildPopupWindow(null);

			setRedirect(`/g/${guildId}/admin/onboard`);
		});
		return () => websocket.off('guild.added');
	}, [guildPopupWindow]);*/

	useEffect(() => {
		if (guildData) {
			setFilteredGuilds(guildData.guilds || []);
			setFilteredGuildsToAdd(guildData.guildsToAdd || []);
			setFilteredDeveloperGuilds(guildData.developerGuilds || []);
			setGuildsSearch('');
			setGuildsToAddSearch('');
			setDeveloperGuildsSearch('');
		}
	}, [guildData])

	function onGuildsSearchUpdate(event) {
		if (!guildData || !guildData.guilds) return;

		const value = event.target.value;
		setGuildsSearch(value);

		if (value) {
			setFilteredGuilds(guildData.guilds.filter(guild => guild.name.toLowerCase().includes(value.toLowerCase()) || guild.id.includes(value)));
		}
		else setFilteredGuilds(guildData.guilds);
	}
	function onGuildAddSearchUpdate(event) {
		if (!guildData || !guildData.guildsToAdd) return;

		const value = event.target.value;
		setGuildsToAddSearch(value);

		if (value) {
			setFilteredGuildsToAdd(guildData.guildsToAdd.filter(guild => guild.name.toLowerCase().includes(value.toLowerCase()) || guild.id.includes(value)));
		}
		else setFilteredGuildsToAdd(guildData.guildsToAdd);
	}
	function onDeveloperGuildsSearchUpdate(event) {
		if (!guildData || !guildData.developerGuilds) return;
		
		const value = event.target.value;
		setDeveloperGuildsSearch(value);

		if (value) {
			setFilteredDeveloperGuilds(guildData.developerGuilds.filter(guild => guild.name.toLowerCase().includes(value.toLowerCase()) || guild.id.includes(value)));
		}
		else setFilteredDeveloperGuilds(guildData.developerGuilds);
	}

	return (
		<div style={{ padding: '2rem', maxWidth: '100rem', margin: '0 auto' }}>
			{ developerMode ||
				<>
					{ onlyShowGuildsToAdd || 
						<>
							<Typography.Title>Select a Guild...</Typography.Title>
							{ guildInfo.isLoading || guildInfo.isError || !guildData || !guildData.guilds ?
								<Skeleton />
							: (
								guildData.guilds.length > 0 ?
								<>
									{ guildData.guilds.length > 3 && <Input placeholder="Search" prefix={<SearchOutlined />} style={{ marginBottom: '1rem' }} onChange={onGuildsSearchUpdate} value={guildsSearch} /> }
									<List grid={{ gutter: 16, xs: 2, sm: 3, md: 4, lg: 6, xl: 6, xxl: 6 }} dataSource={filteredGuilds} renderItem={guild => <GuildSelectorCard guild={guild} key={guild.id} />} locale={{ emptyText: 'No Guilds Found' }} />
								</>
							:
								<p>You are not in any guilds with Mizar</p>
							)}
						</>
					}

					{ onlyShowGuildsToAdd || <Typography.Title level={2} style={{fontSize: '1.8em', marginTop: '3rem'}}>...or add Mizar to a guild you administer</Typography.Title> }
					{ guildInfo.isLoading || guildInfo.isError || !guildData || !guildData.guildsToAdd ?
						<Skeleton />
					: (
						guildData.guildsToAdd.length > 0 ?
						<>
							{ guildData.guildsToAdd.length > 3 && <Input placeholder="Search" prefix={<SearchOutlined />} style={{ marginBottom: '1rem' }} onChange={onGuildAddSearchUpdate} value={guildsToAddSearch} /> }
							<List grid={{ gutter: 16, xs: 2, sm: 3, md: 4, lg: 6, xl: 6, xxl: 6 }} dataSource={filteredGuildsToAdd} renderItem={guild => <GuildSelectorToAddCard guild={guild} key={guild.id} state={state} openAuthPopupWindow={openAuthPopupWindow} />} locale={{ emptyText: 'No Guilds Found' }} />
						</>
						:
						<p>You are not an administrator in any guilds without Mizar</p>
					)}
				</>
			}

			{ developerMode && (
				guildInfo.isLoading || guildInfo.isError || !guildData ?
					<Skeleton />
				:
				<>
					<Typography.Title>Welcome Back, Developer!</Typography.Title>
					<Input placeholder="Search" prefix={<SearchOutlined />} style={{ marginBottom: '1rem' }} onChange={onDeveloperGuildsSearchUpdate} value={developerGuildsSearch} />
					<List grid={{ gutter: 16, xs: 2, sm: 3, md: 4, lg: 6, xl: 6, xxl: 6 }} dataSource={filteredDeveloperGuilds} renderItem={guild => <GuildSelectorCard guild={guild} key={guild.id} />} locale={{ emptyText: 'No Guilds Found' }} />
				</>
			)}
		</div>
	);
}


export default GuildSelector;