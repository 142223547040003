import React from 'react';
import { Button } from 'antd';
import { QuestionCircleOutlined, BookOutlined } from '@ant-design/icons';

export default function Docs( { path, type, float }) {
	if (type === 'button') {
		return (
			<Button href={`${process.env.REACT_APP_DOCS_URL}${path || ''}?utm_source=docs-link&utm_medium=webpanel`} target="_blank" rel="noopener noreferrer" size="small" style={{ float: float ? 'right' : undefined, margin: '0.1rem' }} icon={<BookOutlined />}>
				Docs
			</Button>
		)
	}

	return (
		<a href={`${process.env.REACT_APP_DOCS_URL}${path || ''}?utm_source=docs-link&utm_medium=webpanel`} target="_blank" rel="noopener noreferrer">
			<QuestionCircleOutlined style={{ fontSize: '0.6em', padding: '0.1em 0.4em 0.4em', color: 'var(--color-white)' }} />
		</a>
	)
}