import React from 'react';
import { Typography, Avatar, Tag } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import styles from './GuildMember.module.css';

export default function GuildMember(props) {
    const { guildMember } = props;
    const joinedAt = dayjs(guildMember.joinedAt).format('DD/MM/YYYY HH:mm')

    return (
        <div className={styles.guildMember}>
            <div style={{ margin: '1rem' }}>
                <Avatar size={100} shape="square" src={guildMember.avatarUrl} alt="User avatar" />
            </div>
                       
            <div>
                <Typography.Title level={2}><span style={{ fontSize: '0.8em' }}>Hi, </span>{guildMember.displayName}</Typography.Title>
                <p><CalendarOutlined /><strong> Joined </strong>{joinedAt}</p>

                <div>
                    { guildMember.roles.map(role => <Tag style={{ borderColor: role.color, margin: '0.2rem' }} key={role.id}>{role.name}</Tag> )}
                </div>
            </div>
        </div>
    );
}