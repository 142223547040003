import React from 'react';
import { Typography, List, Avatar, Button, Alert } from 'antd';
import { Link } from 'react-router-dom';

const competitorPrices = [
    {
        name: 'MEE6',
        cost: '$4.99/month, $34.99/year or $99.90 forever',
        avatar: 'https://cdn.mee6.xyz/assets/logo.png'
    },
    {
        name: 'Dyno',
        cost: '$5/month',
        avatar: 'https://dyno.gg/images/v3/dyno-256.jpg'
    },
    {
        name: 'Probot',
        cost: '$9.99/month',
        avatar: 'https://probot.io/static/logo1.jpg'
    },
    {
        name: 'Rythm',
        cost: '$5/month',
        avatar: 'https://rythm.fm/rythm.png'
    }
]

export default function Donate() {
    return (
        <div style={{ padding: '2rem', maxWidth: '100rem', margin: '0 auto' }}>
            <Typography.Title>Support Mizar</Typography.Title>
            <Alert type="info" message="Mizar takes a lot of time to develop and costs money to host on servers 24/7. We therefore rely on the generosity of people like you to keep it running" showIcon={true} />

            <Typography.Title level={2} style={{ marginTop: '1rem' }}>How much should I donate?</Typography.Title>
            <p>Other bots charge a price to unlock a 'premium' version with more features. Our approach is to keep everything free, and hope that people will donate what they feel Mizar is worth to them.</p>
            <List itemLayout="horizontal" size="small" dataSource={competitorPrices} renderItem={competitor => (
                <List.Item>
                    <List.Item.Meta avatar={<Avatar src={competitor.avatar} alt={`${competitor.name} favicon`} />} title={competitor.name} description={competitor.cost} />
                </List.Item>
            )} />

            <Typography.Title level={2} style={{ marginTop: '1rem' }}>What will I get?</Typography.Title>
            <p>Everyone who supports us can receive a special role on our <a href="/support" target="_blank">Support Discord Server</a> and can be listed here. You will also receive a higher priority for support and will have a bigger influence on what features get added next.</p>
            <p>At the current time, however, you will not get any extra features as we believe that they all should be open to everyone.</p>

            <Typography.Title level={2} style={{ marginTop: '1rem' }}>I don't have any money to spare. Is there any other way I can help?</Typography.Title>
            <p>Yes, of course - every little helps! You can help spread the word about Mizar and get it added to as many servers as you can. You can also <Link to="/vote">vote or review Mizar on bot listing sites here.</Link></p>


            <p style={{ fontSize: '1.5em', marginTop: '1rem' }}>Thank You.</p>

            <a href={process.env.REACT_APP_PATREON_URL} target="_blank" rel="noopener noreferrer"><Button type="primary">Support with Patreon</Button></a>
        </div>
    );
}