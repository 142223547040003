import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Prompt, Redirect } from 'react-router-dom';
import { Select, Modal, Slider, Row, Col, InputNumber, Button, Input, Form, Space, Avatar, Table, Switch } from 'antd';
import { PlusOutlined, MinusCircleOutlined, FolderOpenOutlined, PhoneOutlined, NotificationOutlined, MessageOutlined, LockOutlined } from '@ant-design/icons';
import { SketchPicker } from 'react-color';
import axios from '../../../axios';
import timezoneOptions from '../../../timezones.json';
import voices from '../../../voices.json';

function ToggleableField(props) {
	const { id, onComplete, style } = props;
	const [ editing, setEditing ] = useState(false);
	const ref = useRef(null);

	useEffect(() => {
		function handleClick(e) {
			if (ref.current) {
				if (ref.current.contains(e.target)) {
					if(e.target.attributes.setcomplete) {
						if (editing) {
							setEditing(false);
							if (onComplete) onComplete();
						}
					}
					else if (!editing) setEditing(true);
				}
				else {
					if (editing) {
						setEditing(false);
						if (onComplete) onComplete();
					}
				}
			}
        }

        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
	}, [editing, onComplete]);

	return (
		<div ref={ref} style={style} id={id} >
            {editing ? props.children[0] : props.children[1] || null}
        </div>
	)
}

export function ColourField({ id, value: propsValue, onChange, disabled }) {
    const [ value, setValue ] = useState('#000000');

    useEffect(() => {
        setValue(propsValue);
	}, [propsValue])

	if (disabled) {
		return (
			<div style={{backgroundColor: value, borderRadius: '50%', border: '0.1rem solid white', width: '2rem', height: '2rem', cursor: 'not-allowed' }}></div>
		);
	}

	return (
		<ToggleableField onComplete={() => {if (value !== propsValue) onChange(value);}} style={{height: '2rem', width: '2rem'}} id={id}>
			<div style={{position: 'absolute', left: 0, zIndex: 20, color: '#000000'}}>
				<SketchPicker color={value} disableAlpha={true} onChange={colour => setValue(colour.hex)} />
			</div>
			<div style={{backgroundColor: value, borderRadius: '50%', border: '0.1rem solid white', width: '2rem', height: '2rem', cursor: 'pointer' }}></div>
		</ToggleableField>
	);
}

export function InputPercentage({ id, value, onChange, disabled }) {
	return (
		<Row gutter={16} id={id}>
			<Col span={18}>
				<Slider min={0} max={100} value={value} onChange={onChange} disabled={disabled} />
			</Col>
			<Col span={6}>
				<InputNumber min={0} max={100} value={value} onChange={onChange} disabled={disabled} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
			</Col>
		</Row>
	)
}

function checkChannelType(channel, type) {
	if (type === 'text') return channel.text;
	else if (type === 'voice') return channel.voice;
	else return channel.type === type;
}

export function ChannelField({ id, type, channels, value, onChange, canSend, canSpeak, nullable, disabled, style, nsfwOption }) {
	const [ open, setOpen ] = useState(false);
	const filteredChannels = useMemo(() => {
		let filteredChannels = (channels && Array.isArray(channels)) ? [...channels] : [];
		if (type) {
			if (canSend) {
				filteredChannels = filteredChannels.filter(channel => channel.canSend && checkChannelType(channel, type));
			}
			else if (canSpeak) {
				filteredChannels = filteredChannels.filter(channel => channel.canSpeak);
			}
			else {
				filteredChannels = filteredChannels.filter(channel => checkChannelType(channel, type));
			}
		}

		if (nsfwOption) filteredChannels.unshift({ id: '_nsfw', name: 'NSFW Channels', type: 'nsfw' });
		if (nullable) filteredChannels.unshift({ id: '_null', name: 'None', type: 'null' });

		return filteredChannels;
	}, [channels, type, canSend, canSpeak, nsfwOption, nullable]);

	function _onChange(val) {
		if (val === '_null') onChange(null);
		else onChange(val);
	}

	return (
		<Select id={id} showSearch value={value} onChange={_onChange} placeholder="None" disabled={disabled} allowClear={nullable} style={style || { minWidth: '15rem' }} notFoundContent="No Channels Found" filterOption={(search, channel) => {
			search = search.toLowerCase();
			if (channel.key.indexOf(search) !== -1) return true;
			if (channel.props.name.indexOf(search) !== -1) return true;
			if (channel.props.parent && channel.props.parent.indexOf(search) !== -1) return true;
			return false;
		}} dropdownMatchSelectWidth={300} onDropdownVisibleChange={open => setOpen(open)}>
			{ filteredChannels.map(channel => {
				var channelIcon = <NotificationOutlined />;
				var channelName = channel.name;
				switch (channel.type) {
					case 2: // GUILD_VOICE
					case 13: // GUILD_STAGE_VOICE
						channelIcon = <PhoneOutlined />;
						break;
					case 0: // GUILD_TEXT
						channelIcon = <MessageOutlined />;
						channelName = `#${channelName}`;
						break;
					case 4: // GUILD_CATEGORY
						channelIcon = <FolderOpenOutlined />;
						channelName = channelName.toUpperCase();
						break;
					case 'nsfw':
						channelIcon = <LockOutlined />;
						break;
					case 'null':
						channelIcon = null;
						break;
					default:
						break;
				}

				return (
					<Select.Option key={channel.id} name={channel.name.toLowerCase()} parent={channel.parent && channel.parent.toLowerCase()}>
						{ open && <span style={{ float: 'right', color: 'var(--color-white)' }}>{channel.parent}</span> }
						{channelIcon} {channelName}
					</Select.Option>
				);
			})}
		</Select>
	);
}
export function RoleField({ id, roles, value, onChange, nullable, assignable, disabled, style }) {
	const options = useMemo(() => {
		var options = roles.slice();
		if (assignable) options = options.filter(role => role.assignable);
		if (nullable) options.unshift({ id: null, name: 'None' });
		return options;
	}, [roles, nullable, assignable]);

	return (
		<Select id={id} showSearch value={value} onChange={onChange} placeholder="None" disabled={disabled} allowClear={nullable} style={style || { minWidth: '12rem' }} notFoundContent="No Roles Found" filterOption={(search, role) => {
			if (role.key.indexOf(search) !== -1) return true;
			if (role.props.name.indexOf(search.toLowerCase()) !== -1) return true;
			return false;
		}} dropdownMatchSelectWidth={200}>
			{ options.map(role => (
				<Select.Option key={role.id} name={role.name.toLowerCase()}>
					<span style={{ color: role.colour === '#000000' ? undefined : role.colour }}>{role.name}</span>
				</Select.Option>
			))}
		</Select>
	);
}
export function UserField({ id, value, onChange, disabled, guildId, style }) {
	const [ searchQuery, setSearchQuery ] = useState(null);
	const users = useQuery([`/administration/users/${searchQuery}`, guildId], () => {
		if (!searchQuery && value) return axios.get(`/administration/users/${value}`);
		if (!searchQuery || searchQuery.length > 64) return { data: [] };
		return axios.get(`/administration/users/${searchQuery}`);
	});

	return (
		<Select id={id} showSearch value={value} onChange={onChange} placeholder="Search" disabled={disabled} showArrow={false} filterOption={false} notFoundContent={searchQuery ? <p>No users found</p> : <p>Search...</p>} onSearch={query => setSearchQuery(query)} defaultActiveFirstOption={false} style={style || { minWidth: '12rem' }} loading={users.isLoading} dropdownMatchSelectWidth={200}>
			{ users.isSuccess && users.data.map(user => (
				<Select.Option key={user.id}>
					<Avatar src={user.avatar} size="small" style={{ margin: '0.2rem 0.5rem 0.4rem 0' }} />
					<span style={{ color: user.colour === '#000000' ? undefined : user.colour }}>{user.name}</span>
				</Select.Option>
			))}
		</Select>
	);
}

export const ArrayField = React.memo(function ArrayField({ id, name, guildContext, min, max, schema, disabled, onChange, value }) {
	if (schema.type === 'voice') {
		return (
			<Select id={id} mode="multiple" showSearch style={{ width: '100%', minWidth: '10rem' }} placeholder="Voices" optionFilterProp="value" disabled={disabled} onChange={onChange} value={value} options={voices} maxTagCount={max || undefined} />
		);
	}

	var FieldSpacing = ({ children }) => (
		<Space style={{ marginRight: '1rem' }}>
			{children}
		</Space>
	)
	if (schema.block) FieldSpacing = ({ children }) => (
		<div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.3rem' }}>
			{children}
		</div>
	);

	var fieldInput;

	switch (schema.type) {
		case 'string':
			fieldInput = <Input placeholder="None" minLength={schema.min} maxLength={schema.max} disabled={disabled} />;
			break;

		case 'user':
			fieldInput = <UserField guildId={guildContext.id} disabled={disabled} />;
			break;

		case 'command':
			fieldInput = <Input placeholder="None" minLength={schema.min} maxLength={schema.max} addonBefore={guildContext.mainPrefix} disabled={disabled} />;
			break;

		case 'role':
			fieldInput = <RoleField roles={guildContext.roles} assignable={schema.roleAssignable} nullable={schema.nullable} disabled={disabled} />;
			break;

		case 'channel':
			fieldInput = <ChannelField channels={guildContext.channels} type={schema.channelType} canSend={schema.canSend} canSpeak={schema.canSpeak} nullable={schema.nullable} disabled={disabled} />;
			break;

		default:
			return (
				<div>Invalid Schema Type - {schema.type}</div>
			);
	}

	return (
		<Form.List name={name} style={{ display: 'flex' }} id={id}>
			{(fields, { add, remove }, { errors }) => (
				<>
					{ fields.map((field, i) => (
						<FieldSpacing key={i}>
							<Form.Item {...field} noStyle>
								{fieldInput}
							</Form.Item>
							<MinusCircleOutlined onClick={() => disabled || (min && fields.length <= min) || remove(field.name)} style={{ marginLeft: schema.block && '0.5rem', cursor: (disabled || (min && fields.length <= min)) ? 'not-allowed' : undefined, color: (disabled || (min && fields.length <= min)) ? '#dddddd44' : undefined }} />
						</FieldSpacing>
					))}

					<Button type="dashed" onClick={() => add('')} icon={<PlusOutlined />} disabled={disabled || (max && fields.length >= max)}>Add</Button>
				</>
			)}
		</Form.List>
	);
}, (oldProps, newProps) => {
	if (oldProps.name === newProps.name && oldProps.guildContext === newProps.guildContext && oldProps.min === newProps.min
	&& oldProps.max === newProps.max && oldProps.schema === newProps.schema && oldProps.disabled === newProps.disabled) return true;

	return false;
});

export function ClosePrompt() {
	const [ showCloseModal, setShowCloseModal ] = useState(false);
	const [ answeredModal, setAnsweredModal ] = useState(false);
	const [ redirect, setRedirect ] = useState(null);

	function handleBlockedNavigation(location, action) {
		setRedirect(location);
		if (answeredModal) {
			return true;
		}
		setShowCloseModal(true);
		return false;
	}

	return (
		<>
			{ answeredModal && redirect ? <Redirect to={redirect} /> :
				<>
					<Prompt message={handleBlockedNavigation} />
					<Modal title="Discard Changes" visible={showCloseModal && !answeredModal} onOk={() => setAnsweredModal(true)} onCancel={() => setShowCloseModal(false)} okText='Discard' cancelText='Back'>
						Navigating away from this page will discard your changes. Are you sure you want to continue?
					</Modal>
				</>
			}
		</>
	);
}

export function SaveSettingsDialog({ cancelChanges }) {
	return (
		<div style={{ position: 'fixed', right: '1rem', bottom: '1rem', zIndex: 2 }}>
			<Button onClick={cancelChanges} type="button" htmlType="button" size="large">Cancel</Button>
			<Button type="primary" htmlType="submit" size="large">Save</Button>
		</div>
	)
}

export const TimezoneField = React.memo(function TimezoneField({ id, value, onChange, placeholder, disabled }) {
	return (
		<Select id={id} showSearch style={{ width: '100%' }} placeholder={placeholder} optionFilterProp="value" filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0} value={value} onChange={onChange} disabled={disabled} options={timezoneOptions} />
	);
});

export const BooleanGroup = React.memo(function BooleanGroup({ id, value, onChange, booleans, groups, disabled }) {
	const mappedGroups = useMemo(() => {
		const _mappedGroups = {};
		Object.keys(booleans).forEach(booleanKey => {
			let boolean = booleans[booleanKey];
			if (!_mappedGroups[boolean.group]) _mappedGroups[boolean.group] = [{ id: booleanKey, name: boolean.title }];
			else _mappedGroups[boolean.group].push({ id: booleanKey, name: boolean.title });
		});

		return _mappedGroups;
	}, [booleans]);
	const colSpan = useMemo(() => Math.floor(24/groups.length), [groups]);

	function setBoolean(id, checked) {
		const _value = Object.assign({}, value);
		_value[id] = checked;
		onChange(_value);
	}

	return (
		<Row justify="space-between" id={id}>
			{ groups.map((column, i) => <Col key={i} span={colSpan}>
				{ column.map(groupName => <div key={groupName} style={{ marginBottom: '1.5rem' }}>
						{ mappedGroups[groupName].map(boolean => <div key={boolean.id} style={{ display: 'flex', alignContent: 'center', marginBottom: '0.4rem' }}>
							<Switch checked={value && value[boolean.id]} onChange={checked => setBoolean(boolean.id, checked)} disabled={disabled} />
							<label style={{ fontWeight: 'normal', marginLeft: '0.5rem' }}>{boolean.name}</label>
						</div>)}
					</div>)}
			</Col>) }
		</Row>
	);
});

export const SelectionSlider = React.memo(function SelectionSlider({ id, value, onChange, schema, disabled }) {
	let marks = {};
	let optionIds = {};
	let optionsArray = Object.entries(schema.options);
	let currentIndex = 0;

	if ((!value) && schema.defaultOption) value = schema.defaultOption;

	let i = 0;
	for (let [ optionId, optionName ] of optionsArray) {
		if (optionId === value) currentIndex = i;
		marks[i] = optionName;
		optionIds[i] = optionId;
		i++;
	}

	function onUpdate(value) {
		onChange(optionIds[value]);
	}

	return (
		<Slider id={id} step={null} marks={marks} disabled={disabled} value={currentIndex} onChange={onUpdate} min={0} max={i-1} tooltipVisible={false} />
	);
});

export const ErrorResponses = React.memo(function ErrorResponses({ id, value, onChange, errors, disabled }) {
	const rows = useMemo(() => Object.keys(errors).map(errorKey => ({
		id: errorKey,
		name: errors[errorKey]
	})), [errors]);

	function setReact(id, checked) {
		const _value = Object.assign({}, value || {});
		_value[id] = Object.assign({}, _value[id] || {
			react: false,
			message: ''
		});

		_value[id].react = checked;
		onChange(_value);
	}
	function setMessage(id, message) {
		const _value = Object.assign({}, value || {});
		_value[id] = Object.assign({}, _value[id] || {
			react: false,
			message: ''
		});

		_value[id].message = message;
		onChange(_value);
	}

	return (
		<Table dataSource={rows} pagination={false} rowKey="id" id={id}>
			<Table.Column title="Error" dataIndex="name" key="name" width={150} />
			<Table.Column title="React ❌" dataIndex="id" key="react" width={100} render={id => <Switch checked={value && value[id] && value[id].react} onChange={checked => setReact(id, checked)} disabled={disabled} />} />
			<Table.Column title="Message" dataIndex="id" key="message" render={id => <Input placeholder="No Message" maxLength={300} value={(value && value[id] && value[id].message) || ''} onChange={e => setMessage(id, e.target.value)} disabled={disabled} />} />
		</Table>
	);
});