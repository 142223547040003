import React, { useState, useMemo, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import axios from '../../../axios';
import { Tabs, Typography, Form, Skeleton, Button, Modal, Tooltip, Grid, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { LoadingDimmer } from '../../Components';
import { ClosePrompt, SaveSettingsDialog } from './FormElements';
import OptionFields, { findSettingsChanges } from './OptionFields';
import ReactGA from 'react-ga';
//import Docs from '../../Docs';

const FunctionTab = React.memo(function FunctionTab({ func, installed, resetFunction, guildContext, setRestrictionDefinition, restrictionChanges, form, updateCount }) {
	const { schema: rawSchema } = func;

	const schema = useMemo(() => {
		let _schema = {};
		for (let [fieldId, fieldSchema] of Object.entries(rawSchema)) {
			if (!fieldSchema.position) continue;

			let row = _schema[fieldSchema.position] || [];
			row.push({ ...fieldSchema, id: fieldId });
			_schema[fieldSchema.position] = row;
		}
		return Object.values(_schema).sort((a, b) => a[0].position - b[0].position);
	}, [rawSchema]);

	return (
		<>
			<Typography.Title level={3}>{func.name}{func.nsfw && <Tooltip title="This function may return a NSFW response"><span style={{ color: '#ee3333', fontSize: '0.6em' }}> NSFW</span></Tooltip>}</Typography.Title>
			<p style={{ fontSize: '1.1em', marginBottom: '0.5rem' }}>{func.description}</p>
			<p style={{ fontSize: '0.9em' }}>{func.tooltip}</p>

			{ installed ? 
				<OptionFields schema={schema} functionId={func.id} guildContext={guildContext} setRestrictionDefinition={setRestrictionDefinition} restrictionChanges={restrictionChanges} form={form} updateCount={updateCount} />
				:
				<Button type="primary" onClick={resetFunction}>Install Function</Button>
			}
		</>
	);
});


function Module({ id: moduleId, name, guildContext, guildId }) {
	const queryClient = useQueryClient();
	const moduleInfo = useQuery([`/administration/settings/${moduleId}`, guildId]);
	const { data: moduleData } = moduleInfo;

	const [ restrictionChanges, setRestrictionChanges ] = useState({});

	const initialSettings = useMemo(() => (moduleData && moduleData.options) || {}, [moduleData]);
	const description = useMemo(() => (moduleData && moduleData.schema.description) || '', [moduleData]);
	const functions = useMemo(() => (moduleData && moduleData.schema.functions) || [], [moduleData]);
	const schema = useMemo(() => {
		if (!moduleData) return [];

		let _schema = {};
		for (let [fieldId, fieldSchema] of Object.entries(moduleData.schema.schema)) {
			if (!fieldSchema.position) continue;

			let row = _schema[fieldSchema.position] || [];
			row.push({ ...fieldSchema, id: fieldId });
			_schema[fieldSchema.position] = row;
		}
		return Object.values(_schema).sort((a, b) => a[0].position - b[0].position);
	}, [moduleData]);

	const [ loading, setLoading ] = useState(false);
	const [ changed, setChanged ] = useState(0);
	const [ form ] = Form.useForm();
	const breakpoints = Grid.useBreakpoint();

	useEffect(() => {
		form.resetFields();
		setChanged(0);
	}, [initialSettings, form]);

	function resetFunction(functionId) {
		if (process.env.REACT_APP_ANALYTICS_KEY) ReactGA.event({
			category: 'Admin Panel',
			action: 'Reset Function',
			transport: 'beacon'
		});

		setLoading(true);
		axios.delete(`/administration/settings/${moduleId}/${functionId}`).then(data => {
			queryClient.setQueryData([`/administration/settings/${moduleId}`, guildId], data);
			queryClient.invalidateQueries(['/administration/restrictions', guildId]);
			message.info('Function reset');
			setLoading(false);
		}).catch(() => {
			message.error('An error occured');
			setLoading(false);
		});
	}

	function cancelChanges() {
		form.resetFields();
		setRestrictionChanges({});
		setChanged(0);
	}
	function submitChanges(values) {
		if (restrictionChanges && Object.keys(restrictionChanges).length > 0) values._restrictions = restrictionChanges;

		let changes = findSettingsChanges(initialSettings, values);
		if (!changes) return cancelChanges();

		if (process.env.REACT_APP_ANALYTICS_KEY) ReactGA.event({
			category: 'Admin Panel',
			action: 'Save Module',
			transport: 'beacon'
		});

		setLoading(true);
		axios.put(`/administration/settings/${moduleId}`, { changes }).then(data => {
			queryClient.setQueryData([`/administration/settings/${moduleId}`, guildId], data);
			if (restrictionChanges && Object.keys(restrictionChanges).length > 0) queryClient.invalidateQueries(['/administration/restrictions', guildId]);
			message.info('Settings saved');
			cancelChanges();
			setLoading(false);
		}).catch(error => {
			if (error.response && error.response.data && error.response.data.errors) message.error(error.response.data.errors.join('\n'));
			else message.error('An error occured');

			window.scrollTo(0, 0);
			setLoading(false);
		});
	}
	
	function resetToDefault() {
		if (process.env.REACT_APP_ANALYTICS_KEY) ReactGA.event({
			category: 'Admin Panel',
			action: 'Reset Module',
			transport: 'beacon'
		});

		setLoading(true);
		axios.delete(`/administration/settings/${moduleId}`).then(data => {
			queryClient.setQueryData([`/administration/settings/${moduleId}`, guildId], data);
			queryClient.invalidateQueries(['/administration/restrictions', guildId]);
			message.info('Module reset');
			setLoading(false);
		}).catch(() => {
			message.error('An error occured');
			setLoading(false);
		});
	}

	function onResetClicked() {
		Modal.confirm({
			title: 'Reset Module to Default',
			content: 'Are you sure you want to reset your configuration? This action cannot be undone.',
			okText: 'Delete',
			okType: 'danger',
			icon: <DeleteOutlined />,
			onOk: resetToDefault
		});
	}

	function setRestrictionDefinition(name, definition) {
		const _changes = Object.assign({}, restrictionChanges);
		_changes[name] = definition;
		setChanged(i => i + 1);
		setRestrictionChanges(_changes);
	}

	return (
		<div style={{ padding: '1rem 2rem' }}>
			{ moduleInfo.isSuccess && <Button danger onClick={onResetClicked} type="primary" style={{ float: 'right' }}>Reset to Default</Button> }
			<Typography.Title level={2}>{name} Module Settings</Typography.Title>

			{ changed ? <ClosePrompt /> : null }
			{ loading && <LoadingDimmer /> }

			{ !moduleInfo.isSuccess ? <><Skeleton active /><Skeleton active /><Skeleton active /><Skeleton active /><Skeleton active /></> :
				<>
					<p style={{ fontSize: '1.1em', marginBottom: '2rem' }}>{description}</p>

					<Form layout="vertical" form={form} onValuesChange={() => setChanged(i => i + 1)} onFinish={submitChanges} initialValues={initialSettings} requiredMark={false} scrollToFirstError>
						{ initialSettings ?
							<>
								{ changed ? <SaveSettingsDialog cancelChanges={cancelChanges} /> : null }

								<OptionFields schema={schema} guildContext={guildContext} setRestrictionDefinition={setRestrictionDefinition} restrictionChanges={restrictionChanges} form={form} updateCount={changed} />

								{ functions && functions.length > 0 &&
									<>
										<Typography.Title level={3}>Functions</Typography.Title>
										<Tabs tabPosition={breakpoints.md ? 'left': 'top'}>
											
											{ functions.map(func => { 
												const funcOptions = initialSettings && initialSettings.functions && initialSettings.functions[func.id];
												let text = funcOptions && funcOptions.formattedCommands ? <div style={{ maxWidth: '12rem', whiteSpace: breakpoints.md ? 'normal' : 'nowrap', textAlign: 'left', overflow: 'hidden' }}>{func.name} | <small>{funcOptions.formattedCommands}</small></div> : func.name;
												if (!funcOptions || !funcOptions.enabled) text = <del>{text}</del>

												return (
													<Tabs.TabPane tab={text} key={func.id}>
														<FunctionTab func={func} installed={!!funcOptions} resetFunction={() => resetFunction(func.id)} guildContext={guildContext} setRestrictionDefinition={setRestrictionDefinition} restrictionChanges={restrictionChanges} form={form} updateCount={changed} />
													</Tabs.TabPane>
												);
											})}
										</Tabs>
									</>
								}

								
							</>
							:
							<Button primary onClick={resetToDefault} type="button">Install Module</Button>
						}
					</Form>
					
				</>
			}
		</div>
	);
}

export default Module;