import React, {  useState } from 'react';
import axios from '../../../axios';
import { Form, Button, Input, Typography, Modal, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { LoadingDimmer } from '../../Components';

export default function DeveloperSettings() {
	const [ loading, setLoading ] = useState(false);
	const [ form ] = Form.useForm();

	function resetToDefault() {
		setLoading(true);
		axios.delete(`/administration/settings`).then(() => {
			message.info('Settings reset');
			setLoading(false);
		}).catch(() => {
			message.error('An error occured');
			setLoading(false);
		});
	}
	function onResetClicked() {
		Modal.confirm({
			title: 'Reset Module to Default',
			content: 'Are you sure you want to reset your configuration? This action cannot be undone.',
			okText: 'Delete',
			okType: 'danger',
			icon: <DeleteOutlined />,
			onOk: resetToDefault
		});
	}

	function sendAdminMessage({ message: messageContent }) {
		setLoading(true);
		axios.post(`/administration/developer-message`, { message: messageContent }).then(data => {
			message.info((data.data && data.data.message) || 'Message could not be sent');
			form.resetFields();
			setLoading(false);
		}).catch(() => {
			message.error('An error occured');
			setLoading(false);
		});
	}

	function clearConfigCache() {
		setLoading(true);
		axios.delete(`/administration/cache`).then(() => {
			message.info('Cache cleared');
			setLoading(false);
		}).catch(() => {
			message.error('An error occured');
			setLoading(false);
		});
	}

	return (
		<div style={{ padding: '1rem 2rem' }}>
			<div style={{ float: 'right' }}>
				<Button danger onClick={clearConfigCache} type="primary" style={{ margin: '0.5rem' }}>Clear Cache</Button>
				<Button danger onClick={onResetClicked} type="primary" style={{ margin: '0.5rem' }}>Reset to Default</Button>
			</div>

			<Typography.Title level={2}>Developer Settings</Typography.Title>

			{ loading && <LoadingDimmer /> }

			<Form onFinish={sendAdminMessage} layout="vertical" requiredMark={false} form={form}>
				<Typography.Title level={3}>Send Bot Admin Message</Typography.Title>

				<Form.Item name="message" label="Message" rules={[
					{ required: true, message: 'Please enter a message' },
					{ min: 1, message: 'Must be more than 500 characters' },
					{ max: 500, message: 'Must be less than 500 characters' }
				]}>
					<Input.TextArea allowClear autoSize={{ minRows: 4 }} maxLength={500} />
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit">Send</Button>
				</Form.Item>
			</Form>
		</div>
	);
}