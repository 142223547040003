import React from 'react';
import { useQuery } from 'react-query';
import { List, message, Typography, Grid, Space, Alert } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import { Fade } from 'react-awesome-reveal';

function Changelog() {
    const changelog = useQuery('/changelog', { onError: () => message.error("Changelog couldn't be fetched.") });
    const screens = Grid.useBreakpoint();

    return (
        <div style={{ padding: '2rem', maxWidth: '100rem', margin: '0 auto' }}>
            <Typography.Title>Changelog</Typography.Title>

            <Alert type="info" message="A list of what's new" showIcon={true} />

            <List itemLayout="vertical" loading={changelog.isLoading} dataSource={changelog.isSuccess ? changelog.data : []} style={{ padding: '0 1rem' }} locale={{ emptyText: 'No updates found' }} renderItem={version => (
                <Fade duration={500} triggerOnce key={version.name}>
                    <List.Item key={version.name} style={{ padding: '2rem 0' }} actions={[<Space><CalendarOutlined />{version.date}</Space>]}>
                        <List.Item.Meta title={`Version ${version.name}`} description={version.description || ''} />

                        <div style={{ margin: '0.5rem 0' }}>
                            <Typography.Title level={5} style={{ fontSize: '1.05em', fontWeight: 'normal' }}>Changed</Typography.Title>
                            <ul style={{ columns: screens.md ? 2 : 1 }}>
                                { version.changes?.map(item => <li key={item}>{item}</li>) }
                            </ul>
                        </div>

                        { version.newFunctions && version.newFunctions.length > 0 && (
                            <div style={{ margin: '0.5rem 0' }}>
                                <Typography.Title level={5} style={{ fontSize: '1.05em', fontWeight: 'normal' }}>Added</Typography.Title>
                                <ul style={{ columns: screens.md ? 2 : 1 }}>
                                    { version.newFunctions.map(item => <li key={item}>{item}</li>) }
                                </ul>
                            </div>
                        )}

                        { version.updatedFunctions && version.updatedFunctions.length > 0 && (
                            <div style={{ margin: '0.5rem 0' }}>
                                <Typography.Title level={5} style={{ fontSize: '1.05em', fontWeight: 'normal' }}>Updated</Typography.Title>
                                <ul style={{ columns: screens.md ? 2 : 1 }}>
                                    { version.updatedFunctions.map(item => <li key={item}>{item}</li>) }
                                </ul>
                            </div>
                        )}
                    </List.Item>
                </Fade>
            )} />
        </div>
    )
}

export default Changelog;