import React from 'react';
import { Space, Divider } from 'antd';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <Space split={<Divider type="vertical" />} style={{ justifyContent: 'center', width: '100%', fontSize: '0.8em' }} wrap>
            <a href={process.env.REACT_APP_DOCS_URL+'/privacy?utm_source=footer&utm_medium=webpanel'} target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            <a href={`${process.env.REACT_APP_DOCS_URL}?utm_source=footer&utm_medium=webpanel`} target="_blank" rel="noopener noreferrer"> Docs</a>
            <Link to="/vote"> Vote</Link>
            <Link to="/donate"> Donate</Link>
            <a href={process.env.REACT_APP_ROADMAP_URL} target="_blank" rel="noopener noreferrer"> Roadmap</a>
            <a href={process.env.REACT_APP_DISCORD_URL} target="_blank" rel="noopener noreferrer"> Support Discord</a>
            <a href={process.env.REACT_APP_TWITTER_URL} target="_blank" rel="noopener noreferrer"> Twitter</a>
        </Space>
    );
}

export default Footer;